import serverInitialState from "./app/server.initialState";
import appUiState from "./app/appUi.initialState";
import authInitialState from "./auth/auth.initialState";
import organisationInitialState from "./organisations/organisations.initialState";
import subscriptionsInitialState from "./subscriptions/subscriptions.initialState";
import rolesInitialState from "./settings/roles/roles.initialState";
import logDetailsInitiaState from "./settings/logDetails/logDetails.initialState";
import permissionsInitialState from "./settings/permissions/permissions.initialState";
import organisationDetailsInitialState from "./administration/organisationDetails/organisationDetails.initialState";
import manualsAndPoliciesInitialState from "./administration/manualsAndPolicies/manualsAndPolicies.initialState";
import rejectionsInitialState from "./app/rejections.initialState";
import organisationUsersInitialState from "./humanResource/organisationUsers/organisationUsers.initialState";
import chartOfAccountsInitialState from "./finance/chartOfAccounts/chartOfAccounts.initialState";
import userProfileInitialState from "./settings/userProfile/userProfile.initialState";
import financeStreamsInitialState from "./businessDevelopmentAndPartnerships/financeStreams/financeStreams.initialState";
import learnersInitialState from "./administration/learners/learners.initialState";
import banksInitialState from "./finance/banks/banks.initialState";
import partnersInitialState from "./businessDevelopmentAndPartnerships/partners/partners.initialState";
import budgetsInitiaState from "./finance/budgets/budgets.initialState";
import assetsInitialState from "./assetsAndInventory/assetRegister/assets.initialState";
import assetTypesInitialState from "./assetsAndInventory/assetRegister/assetTypes.initialState";
import incomeReceiptsInitialState from "./finance/incomeReceipts/incomeReceipts.initialState";
import storesInitialState from "./assetsAndInventory/stores/stores.initialState";
import requisitionMemoInitialState from "./finance/requisitionMemos/requisitionMemos.initialState";
import projectsInitialState from "./projects/projects.initialState";
import ledgersInitialState from "./finance/ledgers/ledgers.initialState";
import requestForQuotationsInitialState from "./procurement/requestForQuotations/requestForQuotations.initialState";
import paymentVouchersInitialState from "./finance/paymentVouchers/paymentVouchers.initialState";
import classesInitialState from "./administration/classes/classes.initialState";
import academicYearsInitialState from "./administration/academicYears/academicYears.initialState";
import paymentStructuresInitialState from "./administration/paymentStructures/paymentStructures.initialState";
import feesCollectionInitialState from "./administration/feesCollectionData/feesCollectionData.inititalState";
import advanceAccountabilityFormsInitialState from "./finance/advanceAccountabilityForms/advanceAccountabilityForms.initialState";
import cashBookInititalState from "./finance/cashBook/cashBook.initialState";
import bidEvaluationsInitialState from "./procurement/bidEvaluations/bidEvaluations.initialState";
import enrollmentInitialState from "./administration/enrollment/enrollment.initialState";
import subjectClassInitialState from "./academics/subjectClass/subjectClass.inititalState";
import teacherAssignmentInitialState from "./academics/teacherAssignment/teacherAssignment.initialState";
import examinationsInitialState from "./academics/examinations/examinations.initialState";
import optionalSubjectsInitialState from "./academics/optionalSubjects/optionalSubjects.initialState";
import subjectsInitialState from "./academics/subjects/subjects.initialState";
import recordOfBiddersInitialState from "./procurement/recordOfBidders/recordOfBidders.initialState";
import noticesOfBestBiddersInitialState from "./procurement/noticesOfBestBidders/noticesOfBestBidders.initialState";
import gradingInitialState from "./academics/grading/grading.initialState";
import bursariesInitialState from "./administration/bursaries/bursaries.initialState";
import localPurchaseOrdersInitialState from "./procurement/localPurchaseOrders/localPurchaseOrders.initialState";
import marksUploadInitialState from "./academics/marksUpload/marksUpload.initialState";
import studentReportsInitialState from "./academics/studentReports/studentReports.initialState";
import incomeStatementInitialState from "./finance/incomeStatement/incomeStatement.inititalState";
import dashboardsInitialState from "./app/dashboard";
import singleLearnerLedgerInitialState from "./administration/singleLearnerLedger/singleLearnerLedger.initialState";
import transferInstitutionInitialState from "./settings/transferInstitution/transferInstitution.initialState";
import learnerBillingInitialState from "./administration/learnerBilling/learnerBilling.initialState";
import performanceAnalysisInitialState from "./academics/performanceAnalysis.initialState";
import loansRepaymentScheduleInititalState from "./finance/loansRepaymentSchedule.initialState";
import supplierPaymentsInitialState from "./finance/supplierPayments/supplierPayments.initialState";
import learnerAttendanceInitialState from "./administration/learnerAttendance/learnerAttendance.initialState";
import staffAttendanceInitialState from "./humanResource/staffAttendance/staffAttendance.initialState";
import schoolCommunicationsInitialState from "./administration/schoolCommunications/schoolCommunications.initialState";
import schoolDevelopmentPlanInitialState from "./administration/schoolDevelopmentPlan/schoolDevelopmentPlan.initialState";
import staffPayrollInitialState from "./humanResource/staffPayRoll/staffPayRoll.initialState";
import clientInvoicesInitialState from "./finance/clientInvoices/clientInvoices.initialState";
import prepaymentsInitialState from "./finance/prepayments/prepayments.initialState";
import receivablesInitialState from "./finance/receivables/receivables.initialState";
import brmsComplianceInitialState from "./administration/brmsCompliance/brmsCompliance.initialState";
import moesDocumentsInitialState from "./moesDocuments/moesDocuments.initialState";
import schoolGatePassInitialState from "./administration/schoolGatePass/schoolGatePass.initialState";
import timetableInitialState from "./academics/timetable/timetable.initialState";
import schemeOfWorkInititalState from "./academics/schemeOfWork/schemeOfWork.initialState";
import lessonPlansInitialState from "./academics/lessonPlans/lessonPlans.initialState";
import learnerIndisciplineInitialState from "./administration/learnerIndiscipline/learnerIndiscipline.initialState";
import ucedInitialState from "./uced/uced.initialState";
import subjectCombinationsInitialState from "./academics/subjectCombinations/subjectCombinations.initialState";

export default {
  ...loansRepaymentScheduleInititalState,
  ...serverInitialState,
  ...appUiState,
  ...authInitialState,
  ...dashboardsInitialState,
  ...organisationInitialState,
  ...subscriptionsInitialState,
  ...rolesInitialState,
  ...marksUploadInitialState,
  ...incomeStatementInitialState,
  ...logDetailsInitiaState,
  ...permissionsInitialState,
  ...organisationDetailsInitialState,
  ...manualsAndPoliciesInitialState,
  ...rejectionsInitialState,
  ...organisationUsersInitialState,
  ...chartOfAccountsInitialState,
  ...userProfileInitialState,
  ...financeStreamsInitialState,
  ...learnersInitialState,
  ...banksInitialState,
  ...partnersInitialState,
  ...budgetsInitiaState,
  ...assetsInitialState,
  ...assetTypesInitialState,
  ...incomeReceiptsInitialState,
  ...storesInitialState,
  ...requisitionMemoInitialState,
  ...projectsInitialState,
  ...ledgersInitialState,
  ...requestForQuotationsInitialState,
  ...paymentVouchersInitialState,
  ...classesInitialState,
  ...academicYearsInitialState,
  ...paymentStructuresInitialState,
  ...feesCollectionInitialState,
  ...cashBookInititalState,
  ...advanceAccountabilityFormsInitialState,
  ...bidEvaluationsInitialState,
  ...enrollmentInitialState,
  ...studentReportsInitialState,
  ...subjectClassInitialState,
  ...teacherAssignmentInitialState,
  ...examinationsInitialState,
  ...optionalSubjectsInitialState,
  ...subjectsInitialState,
  ...recordOfBiddersInitialState,
  ...noticesOfBestBiddersInitialState,
  ...gradingInitialState,
  ...bursariesInitialState,
  ...localPurchaseOrdersInitialState,
  ...singleLearnerLedgerInitialState,
  ...transferInstitutionInitialState,
  ...learnerBillingInitialState,
  ...performanceAnalysisInitialState,
  ...loansRepaymentScheduleInititalState,
  ...supplierPaymentsInitialState,
  ...learnerAttendanceInitialState,
  ...staffAttendanceInitialState,
  ...schoolCommunicationsInitialState,
  ...schoolDevelopmentPlanInitialState,
  ...staffPayrollInitialState,
  ...prepaymentsInitialState,
  ...receivablesInitialState,
  ...clientInvoicesInitialState,
  ...brmsComplianceInitialState,
  ...moesDocumentsInitialState,
  ...schoolGatePassInitialState,
  ...timetableInitialState,
  ...schemeOfWorkInititalState,
  ...lessonPlansInitialState,
  ...learnerIndisciplineInitialState,
  ...ucedInitialState,
  ...subjectCombinationsInitialState,
};
