import { takeLatest, put, fork, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { subjectCombinationsActions } from "../../../actions";
const FileDownload = require("js-file-download");

function* getSubjectCombinations() {
  try {
    const response = yield axios.get("/academics/subject-combinations");
    yield put({
      type: subjectCombinationsActions.GET_SUBJECT_COMBINATIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectCombinationsActions.GET_SUBJECT_COMBINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* getSubjectCombinationsWatcher() {
  yield takeLatest(
    subjectCombinationsActions.GET_SUBJECT_COMBINATIONS_REQUEST,
    getSubjectCombinations
  );
}

function* addSubjectCombination({ data }) {
  try {
    const response = yield axios.post("/academics/subject-combinations", data);
    yield put({
      type: subjectCombinationsActions.ADD_SUBJECT_COMBINATION_SUCCESS,
      data: response,
    });
    yield put(subjectCombinationsActions.getSubjectCombinations());
  } catch (error) {
    yield put({
      type: subjectCombinationsActions.ADD_SUBJECT_COMBINATION_ERROR,
      error: error.data,
    });
  }
}

function* addSubjectCombinationWatcher() {
  yield takeEvery(
    subjectCombinationsActions.ADD_SUBJECT_COMBINATION_REQUEST,
    addSubjectCombination
  );
}

function* getSubjectCombinationDetails({ id }) {
  try {
    const response = yield axios.get(`/academics/subject-combinations/${id}`);
    yield put({
      type: subjectCombinationsActions.GET_SUBJECT_COMBINATION_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectCombinationsActions.GET_SUBJECT_COMBINATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getSubjectCombinationDetailsWatcher() {
  yield takeEvery(
    subjectCombinationsActions.GET_SUBJECT_COMBINATION_DETAILS_REQUEST,
    getSubjectCombinationDetails
  );
}

function* updateSubjectCombination({ id, data }) {
  try {
    const response = yield axios.put(
      `/academics/subject-combinations/${id}`,
      data
    );
    yield put({
      type: subjectCombinationsActions.UPDATE_SUBJECT_COMBINATION_SUCCESS,
      data: response,
    });
    yield put(subjectCombinationsActions.getSubjectCombinations());
  } catch (error) {
    yield put({
      type: subjectCombinationsActions.UPDATE_SUBJECT_COMBINATION_ERROR,
      error: error.data,
    });
  }
}

function* updateSubjectCombinationWatcher() {
  yield takeEvery(
    subjectCombinationsActions.UPDATE_SUBJECT_COMBINATION_REQUEST,
    updateSubjectCombination
  );
}

function* deleteSubjectCombination({ id }) {
  try {
    const response = yield axios.delete(
      `/academics/subject-combinations/${id}`
    );
    yield put({
      type: subjectCombinationsActions.DELETE_SUBJECT_COMBINATION_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
  } catch (error) {
    yield put({
      type: subjectCombinationsActions.DELETE_SUBJECT_COMBINATION_ERROR,
      error: error.data,
    });
  }
}

function* deleteSubjectCombinationWatcher() {
  yield takeEvery(
    subjectCombinationsActions.DELETE_SUBJECT_COMBINATION_REQUEST,
    deleteSubjectCombination
  );
}

function* uploadSubjectCombinationDocument({ data }) {
  try {
    const response = yield axios.post(
      `/academics/subject-combinations/upload-template`,
      data
    );
    yield put({
      type: subjectCombinationsActions.UPLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectCombinationsActions.UPLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR,
      error: error.data,
    });
  }
}

function* uploadSubjectCombinationDocumentWatcher() {
  yield takeEvery(
    subjectCombinationsActions.UPLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST,
    uploadSubjectCombinationDocument
  );
}

function* downloadSubjectCombinationDocument() {
  try {
    const response = yield axios.get(
      `/academics/subject-combinations/download-template`,
      {
        responseType: "blob",
      }
    );
    FileDownload(response.data, "SUBJECT COMBINATIONS TEMPLATE.xlsx");
    yield put({
      type: subjectCombinationsActions.DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectCombinationsActions.DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR,
      error: error.data,
    });
  }
}

function* downloadSubjectCombinationDocumentWatcher() {
  yield takeEvery(
    subjectCombinationsActions.DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST,
    downloadSubjectCombinationDocument
  );
}

export default [
  fork(getSubjectCombinationsWatcher),
  fork(addSubjectCombinationWatcher),
  fork(getSubjectCombinationDetailsWatcher),
  fork(updateSubjectCombinationWatcher),
  fork(deleteSubjectCombinationWatcher),
  fork(uploadSubjectCombinationDocumentWatcher),
  fork(downloadSubjectCombinationDocumentWatcher),
];
