import { combineReducers } from "redux";
import server from "./app/server.reducer";
import appUi from "./app/appUi.reducer";
import auth from "./auth/auth.reducer";
import organisations from "./organisations/organisations.reducer";
import subscriptions from "./subscriptions/subscriptions.reducer";
import roles from "./settings/roles/roles.reducer";
import logDetails from "./settings/logDetails/logDetails.reducer";
import permissions from "./settings/permissions/permissions.reducer";
import organisationDetails from "./administration/organisationDetails/organisationDetails.reducer";
import manualsAndPolicies from "./administration/manualsAndPolicies/manualsAndPolicies.reducer";
import rejections from "./app/rejections.reducer";
import organisationUsers from "./humanResource/organisationUsers/organisationUsers.reducer";
import chartOfAccounts from "./finance/chartOfAccounts/chartOfAccounts.reducer";
import userProfile from "./settings/userProfile/userProfile.reducers";
import financeStreams from "./businessDevelopmentAndPartnerships/financeStreams/financeStreams.reducer";
import learners from "./administration/learners/learners.reducer";
import banks from "./finance/banks/banks.reducer";
import partners from "./businessDevelopmentAndPartnerships/partners/partners.reducer";
import budgets from "./finance/budgets/budgets.reducer";
import assets from "./assetsAndInventory/assetRegister/assets.reducer";
import assetTypes from "./assetsAndInventory/assetRegister/assetTypes.reducer";
import incomeReceipts from "./finance/incomeReceipts/incomeReceipts.reducer";
import stores from "./assetsAndInventory/stores/stores.reducer";
import requisitionMemos from "./finance/requisitionMemos/requisitionMemos.reducer";
import projects from "./projects/projects.reducer";
import ledgers from "./finance/ledgers/ledgers.reducer";
import requestForQuotations from "./procurement/requestForQuotations/requestForQuotations.reducer";
import paymentVouchers from "./finance/paymentVouchers/paymentVouchers.reducer";
import classes from "./administration/classes/classes.reducer";
import academicYears from "./administration/academicYears/academicYears.reducer";
import paymentStructures from "./administration/paymentStructures/paymentStructures.reducer";
import feesCollections from "./administration/feesCollections/feesCollections.reducer";
import advanceAccountabilityForms from "./finance/advanceAccountabilityForms/advanceAccountabilityForms.reducer";
import cashBook from "./finance/cashBook/cashBook.reducer";
import enrollment from "./administration/enrollment/enrollment.reducer";
import subjectClass from "./academics/subjectClass/subjectClass.reducer";
import teacherAssignment from "./academics/teacherAssignment/teacherAssignment.reducer";
import bidEvaluations from "./procurement/bidEvaluations/bidEvaluations.reducer";
import examinations from "./academics/examinations/examinations.reducer";
import optionalSubjects from "./academics/optionalSubjects/optionalSubjects.reducer";
import subjects from "./academics/subjects/subjects.reducer";
import recordOfBidders from "./procurement/recordOfBidders/recordOfBidders.reducer";
import noticesOfBestBidders from "./procurement/noticesOfBestBidders/noticesOfBestBidders.reducer";
import grading from "./academics/grading/grading.reducer";
import bursaries from "./administration/bursaries/bursaries.reducer";
import localPurchaseOrders from "./procurement/localPurchaseOrders/localPurchaseOrders.reducer";
import marksUpload from "./academics/marksUpload/marksUpload.reducer";
import studentReports from "./academics/studentReports/studentReports.reducer";
import incomeStatement from "./finance/incomeStatement/incomeStatement.reducer";
import dashboards from "./app/dashboard.reducer";
import singleLearnerLedger from "./administration/singleLearnerLedger/singleLearnerLedger.reducer";
import transferInstitution from "./settings/transferInstitution/transferInstitution.reducers";
import learnerBilling from "./administration/learnerBilling/learnerBilling.reducer";
import performanceAnalysis from "./academics/performanceAnalysis.reducer";
import loansRepaymentSchedule from "./finance/loanRepaymentSchedule.reducer";
import supplierPayments from "./finance/supplierPayments/supplierPayments.reducer";
import learnerAttendance from "./administration/learnerAttendance/learnerAttendance.reducer";
import staffAttendance from "./humanResource/staffAttendance/staffAttendance.reducer";
import schoolCommunications from "./administration/schoolCommunications/schoolCommunications.reducer";
import schoolDevelopmentPlan from "./administration/schoolDevelopmentPlan/schoolDevelopmentPlan.reducer";
import staffPayRoll from "./humanResource/staffPayRoll/staffPayRoll.reducer";
import clientInvoices from "./finance/clientInvoices/clientInvoices.reducer";
import receivables from "./finance/receivables/receivables.reducer";
import prepayments from "./finance/prepayments/prepayments.reducer";
import brmsCompliance from "./administration/brmsCompliance/brmsCompliance.reducer";
import moesDocuments from "./administration/moesDocuments/moesDocuments.reducer";
import schoolGatePass from "./administration/schoolGatePass/schoolGatePass.reducer";
import timetable from "./academics/timetable/timetable.reducer";
import schemeOfWork from "./academics/schemeOfWork/schemeOfWork.reducer";
import lessonPlans from "./academics/lessonPlans/lessonPlans.reducer";
import learnerIndiscipline from "./administration/learnerIndiscipline/learnerIndiscipline.reducer";
import uced from "./uced/uced.reducer";
import subjectCombinations from "./academics/subjectCombinations/subjectCombinations.reducer";

export default combineReducers({
  loansRepaymentSchedule,
  server,
  cashBook,
  studentReports,
  appUi,
  auth,
  organisations,
  subscriptions,
  incomeStatement,
  roles,
  logDetails,
  permissions,
  organisationDetails,
  manualsAndPolicies,
  rejections,
  organisationUsers,
  chartOfAccounts,
  userProfile,
  financeStreams,
  learners,
  banks,
  partners,
  budgets,
  assets,
  assetTypes,
  incomeReceipts,
  stores,
  requisitionMemos,
  projects,
  ledgers,
  bursaries,
  teacherAssignment,
  requestForQuotations,
  paymentVouchers,
  classes,
  examinations,
  academicYears,
  paymentStructures,
  feesCollections,
  subjectClass,
  advanceAccountabilityForms,
  enrollment,
  bidEvaluations,
  optionalSubjects,
  subjects,
  recordOfBidders,
  noticesOfBestBidders,
  grading,
  localPurchaseOrders,
  marksUpload,
  dashboards,
  singleLearnerLedger,
  transferInstitution,
  learnerBilling,
  performanceAnalysis,
  supplierPayments,
  learnerAttendance,
  staffAttendance,
  schoolCommunications,
  schoolDevelopmentPlan,
  staffPayRoll,
  clientInvoices,
  receivables,
  prepayments,
  brmsCompliance,
  schemeOfWork,
  moesDocuments,
  schoolGatePass,
  timetable,
  lessonPlans,
  learnerIndiscipline,
  uced,
  subjectCombinations,
});
