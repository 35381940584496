const subjectCombinations = {
  GET_SUBJECT_COMBINATIONS_REQUEST: "GET_SUBJECT_COMBINATIONS_REQUEST",
  GET_SUBJECT_COMBINATIONS_SUCCESS: "GET_SUBJECT_COMBINATIONS_SUCCESS",
  GET_SUBJECT_COMBINATIONS_ERROR: "GET_SUBJECT_COMBINATIONS_ERROR",

  ADD_SUBJECT_COMBINATION_REQUEST: "ADD_SUBJECT_COMBINATION_REQUEST",
  ADD_SUBJECT_COMBINATION_SUCCESS: "ADD_SUBJECT_COMBINATION_SUCCESS",
  ADD_SUBJECT_COMBINATION_ERROR: "ADD_SUBJECT_COMBINATION_ERROR",

  GET_SUBJECT_COMBINATION_DETAILS_REQUEST:
    "GET_SUBJECT_COMBINATION_DETAILS_REQUEST",
  GET_SUBJECT_COMBINATION_DETAILS_SUCCESS:
    "GET_SUBJECT_COMBINATION_DETAILS_SUCCESS",
  GET_SUBJECT_COMBINATION_DETAILS_ERROR:
    "GET_SUBJECT_COMBINATION_DETAILS_ERROR",

  UPDATE_SUBJECT_COMBINATION_REQUEST: "UPDATE_SUBJECT_COMBINATION_REQUEST",
  UPDATE_SUBJECT_COMBINATION_SUCCESS: "UPDATE_SUBJECT_COMBINATION_SUCCESS",
  UPDATE_SUBJECT_COMBINATION_ERROR: "UPDATE_SUBJECT_COMBINATION_ERROR",

  SET_SUBJECT_COMBINATION_UPDATE_DATA: "SET_SUBJECT_COMBINATION_UPDATE_DATA",

  DELETE_SUBJECT_COMBINATION_REQUEST: "DELETE_SUBJECT_COMBINATION_REQUEST",
  DELETE_SUBJECT_COMBINATION_SUCCESS: "DELETE_SUBJECT_COMBINATION_SUCCESS",
  DELETE_SUBJECT_COMBINATION_ERROR: "DELETE_SUBJECT_COMBINATION_ERROR",

  UPLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST:
    "UPLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST",
  UPLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS:
    "UPLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS",
  UPLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR:
    "UPLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR",

  DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST:
    "DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST",
  DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS:
    "DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS",
  DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR:
    "DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR",

  getSubjectCombinations: () => ({
    type: subjectCombinations.GET_SUBJECT_COMBINATIONS_REQUEST,
  }),

  addSubjectCombination: (data) => ({
    type: subjectCombinations.ADD_SUBJECT_COMBINATION_REQUEST,
    data,
  }),

  getSubjectCombinationDetails: (id) => ({
    type: subjectCombinations.GET_SUBJECT_COMBINATION_DETAILS_REQUEST,
    id,
  }),

  updateSubjectCombination: (id, data) => ({
    type: subjectCombinations.UPDATE_SUBJECT_COMBINATION_REQUEST,
    id,
    data,
  }),

  setSubjectCombinationUpdateData: (data) => ({
    type: subjectCombinations.SET_SUBJECT_COMBINATION_UPDATE_DATA,
    data,
  }),

  deleteSubjectCombination: (id) => ({
    type: subjectCombinations.DELETE_SUBJECT_COMBINATION_REQUEST,
    id,
  }),

  uploadSubjectCombinationDocument: (data) => ({
    type: subjectCombinations.UPLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST,
    data,
  }),

  downloadSubjectCombinationDocument: () => ({
    type: subjectCombinations.DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST,
  }),
};

export default subjectCombinations;
