import { Avatar, Modal, Input, Form, Card, Button, Alert } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppLogo from "../../../assets/AppLogo.png";
import { AiOutlineLock } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import routePaths from "../../../config/routes/routePaths.routes";
import { authActions } from "../../../config/actions";
import usePrevious from "../../../components/hooks/usePrevious";
import "../../../index.css";

const PasswordresetPage = () => {
  const { resetPassword, resetPasswordError, resetPasswordSuccess } =
    useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const prevState = usePrevious({ resetPasswordSuccess, resetPasswordError });
  const goToLanding = () => navigate(`../${routePaths.landingPage.path}`);

  const location = useLocation();
  let token = location.pathname.split("/");
  token = token[token.length - 1];

  const onFinish = (values) => {
    dispatch(authActions.resetUserPassword(values, token));
  };

  // use effects
  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      resetPasswordError &&
      prevState.resetPasswordError !== resetPasswordError
    ) {
      setError(
        resetPasswordError?.message || resetPasswordError?.server?.message
      );
    }
  }, [resetPasswordError]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(resetPasswordSuccess) &&
      prevState.resetPasswordSuccess !== resetPasswordSuccess
    ) {
      if (resetPasswordSuccess.server?.status === true) {
        navigate("/auth");
        Modal.success({
          title: "SUCCESSFULLY RESET YOUR PASSWORD!",
          content:
            "Kindly log into your schoolbook account with your new password.",
        });
      }
    }
  }, [resetPasswordSuccess]);

  return (
    <div className="container-fluid ">
      <div className="d-flex flex-column jusify-content-center align-items-center py-5">
        <div onClick={goToLanding}>
          <Avatar shape="square" src={AppLogo} size={100} />
        </div>
        <h2 className="mb-3 text-sm text-primary">SCHOOLBOOK</h2>
        <div className="rounded w-100">
          <div className="d-flex justify-content-center">
            <div className="input-lenght shadow mt-1">
              <Card title="RESET YOUR PASSWORD" bordered={false}>
                <Form
                  onFinish={onFinish}
                  name="Reset password Page"
                  autoComplete="off"
                  layout="vertical"
                >
                  {error && <Alert type="error" message={error} showIcon />}
                  <Form.Item
                    name="otp"
                    label="OTP code"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input the OTP code sent to your email!",
                      },
                      {
                        min: 8,
                        message: "Your otp can't be less than 8 characters",
                      },
                      {
                        max: 8,
                        message: "Your otp can't be more than 8 characters",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your new password!",
                      },
                      {
                        min: 8,
                        message:
                          "Your password should be more than 8 characters",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      prefix={<AiOutlineLock className="text-secondary" />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm New Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      {
                        min: 8,
                        message:
                          "Your password should be more than 8 characters",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      prefix={<AiOutlineLock className="text-secondary" />}
                    />
                  </Form.Item>
                  <Form.Item className="mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={resetPassword}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordresetPage;
