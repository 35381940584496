const subjectCombinationsInitialState = {
  subjectCombinations: {},
  subjectCombinationsLoading: false,
  subjectCombinationsError: {},

  addSubjectCombination: {},
  addSubjectCombinationLoading: false,
  addSubjectCombinationError: {},

  updateSubjectCombination: {},
  updateSubjectCombinationLoading: false,
  updateSubjectCombinationError: {},
  updateSubjectCombinationData: {},

  deleteSubjectCombination: {},
  deleteSubjectCombinationLoading: false,
  deleteSubjectCombinationError: {},

  subjectCombinationDetails: {},
  subjectCombinationDetailsLoading: false,
  subjectCombinationDetailsError: {},

  uploadSubjectCombinationDocument: {},
  uploadSubjectCombinationDocumentLoading: false,
  uploadSubjectCombinationDocumentError: {},

  downloadSubjectCombinationDocument: {},
  downloadSubjectCombinationDocumentLoading: false,
  downloadSubjectCombinationDocumentError: {},
};

export default subjectCombinationsInitialState;
