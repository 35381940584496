import { subjectCombinationsActions } from "../../../actions";
import subjectCombinationsInitialState from "../../../initialState/academics/subjectCombinations/subjectCombinations.initialState";
import { filter } from "lodash";

const subjectCombinationsReducer = (
  state = subjectCombinationsInitialState,
  action
) => {
  switch (action.type) {
    case subjectCombinationsActions.GET_SUBJECT_COMBINATIONS_REQUEST:
      return {
        ...state,
        subjectCombinationsLoading: true,
        subjectCombinationsError: {},
      };
    case subjectCombinationsActions.GET_SUBJECT_COMBINATIONS_SUCCESS:
      return {
        ...state,
        subjectCombinations: action.data,
        subjectCombinationsLoading: false,
      };
    case subjectCombinationsActions.GET_SUBJECT_COMBINATIONS_ERROR:
      return {
        ...state,
        subjectCombinationsError: action.error,
        subjectCombinationsLoading: false,
      };

    case subjectCombinationsActions.ADD_SUBJECT_COMBINATION_REQUEST:
      return {
        ...state,
        addSubjectCombinationLoading: true,
        addSubjectCombinationError: {},
      };
    case subjectCombinationsActions.ADD_SUBJECT_COMBINATION_SUCCESS:
      return {
        ...state,
        addSubjectCombination: action.data,
        addSubjectCombinationLoading: false,
      };
    case subjectCombinationsActions.ADD_SUBJECT_COMBINATION_ERROR:
      return {
        ...state,
        addSubjectCombinationError: action.error,
        addSubjectCombinationLoading: false,
      };

    case subjectCombinationsActions.GET_SUBJECT_COMBINATION_DETAILS_REQUEST:
      return {
        ...state,
        subjectCombinationDetailsLoading: true,
        subjectCombinationDetailsError: {},
      };
    case subjectCombinationsActions.GET_SUBJECT_COMBINATION_DETAILS_SUCCESS:
      return {
        ...state,
        subjectCombinationDetails: action.data,
        subjectCombinationDetailsLoading: false,
      };
    case subjectCombinationsActions.GET_SUBJECT_COMBINATION_DETAILS_ERROR:
      return {
        ...state,
        subjectCombinationDetailsError: action.error,
        subjectCombinationDetailsLoading: false,
      };

    case subjectCombinationsActions.UPDATE_SUBJECT_COMBINATION_REQUEST:
      return {
        ...state,
        updateSubjectCombinationLoading: true,
        updateSubjectCombinationError: {},
      };
    case subjectCombinationsActions.UPDATE_SUBJECT_COMBINATION_SUCCESS:
      return {
        ...state,
        updateSubjectCombination: action.data,
        updateSubjectCombinationLoading: false,
      };
    case subjectCombinationsActions.UPDATE_SUBJECT_COMBINATION_ERROR:
      return {
        ...state,
        updateSubjectCombinationError: action.error,
        updateSubjectCombinationLoading: false,
      };
    case subjectCombinationsActions.SET_SUBJECT_COMBINATION_UPDATE_DATA:
      return {
        ...state,
        updateSubjectCombinationData: action.data,
      };

    case subjectCombinationsActions.DELETE_SUBJECT_COMBINATION_REQUEST:
      return {
        ...state,
        deleteSubjectCombinationLoading: true,
        deleteSubjectCombinationError: {},
      };
    case subjectCombinationsActions.DELETE_SUBJECT_COMBINATION_SUCCESS:
      return {
        ...state,
        deleteSubjectCombination: action.data,
        deleteSubjectCombinationLoading: false,
        subjectCombinations: {
          ...state.subjectCombinations,
          subjectCombinations: filter(
            state.subjectCombinations.subjectCombinations,
            (subjectCombination) => subjectCombination.id !== action.data.id
          ),
        },
      };
    case subjectCombinationsActions.DELETE_SUBJECT_COMBINATION_ERROR:
      return {
        ...state,
        deleteSubjectCombinationError: action.error,
        deleteSubjectCombinationLoading: false,
      };
    case subjectCombinationsActions.UPLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST:
      return {
        ...state,
        uploadSubjectCombinationDocumentLoading: true,
        uploadSubjectCombinationDocumentError: {},
      };
    case subjectCombinationsActions.UPLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadSubjectCombinationDocument: action.data,
        uploadSubjectCombinationDocumentLoading: false,
      };
    case subjectCombinationsActions.UPLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR:
      return {
        ...state,
        uploadSubjectCombinationDocumentError: action.error,
        uploadSubjectCombinationDocumentLoading: false,
      };
    case subjectCombinationsActions.DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_REQUEST:
      return {
        ...state,
        downloadSubjectCombinationDocumentLoading: true,
        downloadSubjectCombinationDocumentError: {},
      };
    case subjectCombinationsActions.DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadSubjectCombinationDocument: action.data,
        downloadSubjectCombinationDocumentLoading: false,
      };
    case subjectCombinationsActions.DOWNLOAD_SUBJECT_COMBINATION_DOCUMENT_ERROR:
      return {
        ...state,
        downloadSubjectCombinationDocumentError: action.error,
        downloadSubjectCombinationDocumentLoading: false,
      };
    default:
      return state;
  }
};

export default subjectCombinationsReducer;
