import routePaths from "../../routes/routePaths.routes";

const initialState = {
  appUi: {
    openApps: [],
    activeApp: routePaths.applications.path,
    activeSubApp: "",
    appDrawer: false,
    subAppMenu: false,
    prevOpenSubApp: [],

    studentBillingFormModal: false,
    printTableModal: false,
    printModal: false,

    reportModal: false,
    bulkPrintStudentResults: false,

    singleBillingModal: false,

    addGeneralCommunicationModal: false,
    schoolCommunicationDetailsModal: false,

    addTimetableModal: false,
    updateTimetableModal: false,
    timetableDetails: false,

    printData: {
      title: "",
      columns: [],
      data: [],
    },

    copyBudgetModal: false,

    receivablesLedgerModal: false,
    learnerReceivablesLedgerModal: false,
    promoteLearnersModal: false,
    transferLearnersModal: false,
    prepaymentsLedgerModal: false,
    learnerPrepaymentsLedgerModal: false,

    syncSchoolPayTransactionsModal: false,

    customBillingAmountModal: false,

    addLoanRepaymentScheduleModal: false,
    addLoanRepaymentModal: false,
    loanRepaymentScheduleDetails: false,

    classPerformanceAnalysisDetails: false,
    subjectPerformanceAnalysisDetails: false,

    subscriptionsPaymentModel: false,

    learningAreasModal: false,
    supplementariesModal: false,

    addBrmsComplianceModal: false,
    updateBrmsComplianceModal: false,
    brmsComplianceDetailsModal: false,

    addRoleModal: false,
    updateRolesModal: false,
    roleDetailsModal: false,

    addPermissionsModal: false,

    organisationLogoModal: false,

    addManualsAndPoliciesModal: false,
    updateManualsAndPoliciesModal: false,
    manualsAndPoliciesDetailsModal: false,

    rejectionsModal: false,

    incomeStatementDetails: false,

    forgotPasswordModal: false,

    sendDefaultersCommunicationModal: false,
    addParentChartOfAccountsModal: true,

    addOrganisationUserModal: false,
    updateOrganisationUserModal: false,
    organisationUserDetailsModal: false,

    addAccountTypeModal: false,
    updateAccountTypeModal: false,
    accountTypeDetailsModal: false,

    addAccountModal: false,
    updateAccountModal: false,
    accountDetailsModal: false,

    uploadUserProfileAvatarModal: false,
    uploadUserSignatureModal: false,

    changePasswordModal: false,

    addFinanceStreamModal: false,
    updateFinanceStreamModal: false,
    financeStreamDetailsModal: false,

    addStudentModal: false,
    getStudentDetailsModal: false,
    updateStudentDetailsModal: false,

    addAssetTypeModal: false,
    updateAssetTypeModal: false,
    assetTypeDetailsModal: false,

    addAssetModal: false,
    updateAssetModal: false,
    assetDetailsModal: false,

    addBanksModal: false,
    updateBankModal: false,
    bankDetailsModal: false,

    addPartnersModal: false,
    updatePartnerModal: false,
    partnerDetailsModal: false,

    addBudgetsModal: false,
    updateBudgetModal: false,
    budgetDetailsPage: false,

    addBudgetLineModal: false,
    updateBudgetLineModal: false,
    budgetLineDetailsModal: false,

    updateBudgetStreamModal: false,
    budgetStreamDetailsModal: false,

    addIncomeReceiptModal: false,
    incomeReceiptDetailsModal: false,
    updateIncomeReceiptModal: false,

    addIncomeReceiptStreamModal: false,
    updateIncomeReceiptStreamModal: false,
    incomeReceiptStreamDetailsModal: false,

    addIncomeReceiptDocumentModal: false,

    addStoreItemModal: false,
    updateStoreItemModal: false,
    storeItemDetailsModal: false,
    addStoreIssueModal: false,
    updateStoreIssueModal: false,
    storeIssueDetailsModal: false,

    addRequisitionMemoModal: false,
    updateRequisitionMemoModal: false,
    requisitionMemoDetailsModal: false,
    sortRequisitionMemosModal: false,

    addRequisitionCostMemoModal: false,
    updateRequisitionMemoCostModal: false,
    requisitionMemoCostDetailsModal: false,

    addPaymentVoucherModal: false,
    updatePaymentVoucherModal: false,
    paymentVoucherDetailsModal: false,

    addProjectModal: false,
    updateProjectModal: false,
    projectDetailsModal: false,

    addRequestForQuotationModal: false,
    updateRequestForQuotationModal: false,
    requestForQuotationDetailsModal: false,

    addBidEvaluationModal: false,
    updateBidEvaluationModal: false,
    bidEvaluationDetailsModal: false,
    addEligibilityEvaluationModal: false,
    updateEligibilityEvaluationModal: false,
    eligibilityEvaluationDetailsModal: false,
    addTechnicalEvaluationModal: false,
    updateTechnicalEvaluationModal: false,
    technicalEvaluationDetailsModal: false,
    addFinancialEvaluationModal: false,
    updateFinancialEvaluationModal: false,
    financialEvaluationDetailsModal: false,

    addClassModal: false,
    updateClassModal: false,
    classDetailsModal: false,

    addNoticeOfBestBidderModal: false,
    updateNoticeOfBestBidderModal: false,
    noticeOfBestBidderDetailsModal: false,

    addLocalPurchaseOrderModal: false,
    updateLocalPurchaseOrderModal: false,
    localPurchaseOrderDetailsModal: false,
    addLocalPurchaseOrderItemModal: false,
    updateLocalPurchaseOrderItemModal: false,
    localPurchaseOrderItemDetailsModal: false,

    addAcademicYearsModal: false,
    updateAcademicYearsModal: false,
    academicYearsDetailsModal: false,
    termDetailsModal: false,

    addPaymentStructureModal: false,
    updatePaymentStructureModal: false,
    paymentStructureDetailsModal: false,

    updatePaymentStructureCostModal: false,
    paymentStructureCostDetailsModal: false,

    addAdvanceAccountabilityFormsModal: false,
    updateAdvanceAccountabilityFormsModal: false,
    advanceAccountabilityFormsDetailsModal: false,

    addActualExpenditureModal: false,
    updateActualExpenditureModal: false,
    actualExpenditureDetailsModal: false,

    addEnrollmentModal: false,
    enrollStudentsModal: false,
    enrollmentDetailsModal: false,

    addSubjectClassModal: false,
    updateSubjectClassModal: false,
    subjectClassDetailsModal: false,

    addTeacherAssignmentModal: false,
    teacherAssignmentDetailsModal: false,
    updateTeacherAssignmentModal: false,

    addExaminationModal: false,
    updateExaminationModal: false,
    examinationDetailsModal: false,

    addOptionalSubjectModal: false,
    addOptionalSubjectStudentsModal: false,
    optionalSubjectDetailsModal: false,

    addSubjectModal: false,
    updateSubjectModal: false,
    subjectDetailsModal: false,

    addRecordOfBiddersModal: false,
    updateRecordOfBiddersModal: false,
    recordOfBiddersDetailsModal: false,

    addBidderModal: false,
    updateBidderModal: false,
    bidderDetailsModal: false,

    addGradingModal: false,
    updateGradingModal: false,
    gradingDetailsModal: false,
    addAlevelGradingModal: false,
    updateAlevelGradingModal: false,

    addStreamsModal: false,
    updateStreamsModal: false,
    streamsDetailsModal: false,

    uploadStudentDocumentsModal: false,

    addBursariesModal: false,
    updateBursariesModal: false,
    bursariesDetailsModal: false,
    updateBursaryCostModal: false,
    bursaryCostDetailsModal: false,

    addLedgerModal: false,
    updateLedgerModal: false,
    ledgerDetailsModal: false,
    ledgerAccountDetailsModal: false,

    addMarksUploadModal: false,
    updateMarksUploadModal: false,
    marksUploadDetails: false,
    updateStudentMarksModal: false,
    addStudentMarksModal: false,
    studentMarksTable: false,

    addStudentReportsModal: false,
    updateStudentReportsModal: false,
    studentReportsDetailsModal: false,

    printSubjectsTableModal: false,
    printStudentsTableModal: false,
    printOptionalSubjectsTableModal: false,
    printEnrollmentTableModal: false,

    addStaffDependantsModal: false,
    updateStaffDependantsModal: false,

    uploadStaffDocumentsModal: false,
    updateStaffDocumentsModal: false,

    uploadIncomeReceiptModal: false,
    downloadIncomeReceiptModal: false,

    downloadOptionalSubjectStudentsModal: false,
    uploadOptionalSubjectStudentsModal: false,

    updateEnrollmentModal: false,

    addStaffQualificationModal: false,
    updateStaffQualificationModal: false,
    staffQualificationDetailsModal: false,

    bankStatementModal: false,

    searchStudentsModal: false,

    sortIncomeReceiptsModal: false,

    updateUserEmailModal: false,

    uploadAdvanceAccountabilityDocumentsModal: false,

    sortAdvanceAccountabilityModal: false,

    sortModal: false,

    sortFromTableModal: false,

    addDivisionsModal: false,
    updateDivisionModal: false,

    feesDefaulterDetailsModal: false,

    reportCommentsModal: false,

    billLearnersModal: false,
    mandatoryBillingModal: false,

    createReportExceptionModal: false,
    updateReportExceptionModal: false,
    addSingleReportExceptionModal: false,

    addSupplierPaymentScheduleModal: false,
    updateSupplierPaymentScheduleModal: false,
    supplierPaymentScheduleDetailsModal: false,

    addSupplierPaymentModal: false,
    updateSupplierPaymentModal: false,
    supplierPaymentDetailsModal: false,

    addLearnerAttendanceModal: false,
    updateLearnerAttendanceModal: false,
    learnerAttendanceDetailsModal: false,

    addStaffAttendanceModal: false,
    updateStaffAttendanceModal: false,
    staffAttendanceDetailsModal: false,

    addSupplierPaymentInvoiceModal: false,
    updateSupplierPaymentInvoiceModal: false,
    addGeneralSupplierPaymentInvoiceModal: false,

    addSchoolDevelopmentPlanModal: false,
    updateSchoolDevelopmentPlanModal: false,
    schoolDevelopmentPlanDetailsModal: false,

    addSchoolDevelopmentPlanExpenditureModal: false,
    updateSchoolDevelopmentPlanExpenditureModal: false,
    schoolDevelopmentPlanExpenditureDetailsModal: false,

    addStaffPayRollModal: false,
    updateStaffPayRollModal: false,
    staffPayRollDetailsModal: false,

    addClientInvoiceModal: false,
    updateClientInvoiceModal: false,
    clientInvoiceDetailsModal: false,

    incomeStatementAccountDetailsModal: false,

    addSchoolGatePassModal: false,
    updateSchoolGatePassModal: false,
    schoolGatePassDetailsModal: false,

    addSchoolTimeTableModal: false,
    updateSchoolTimeTableModal: false,
    schoolTimeTableDetails: false,

    addSchoolTimeTableActivityModal: false,
    updateSchoolTimeTableActivityModal: false,
    schoolTimeTableActivityDetailsModal: false,

    schemeOfWorkDetailsModal: false,
    addSchemeOfWorkModal: false,
    updateSchemeOfWorkModal: false,

    addSchemeOfWorkItemModal: false,
    updateSchemeOfWorkItemModal: false,
    schemeOfWorkItemDetailsModal: false,

    addLessonPlanModal: false,
    updateLessonPlanModal: false,
    lessonPlanDetailsModal: false,

    addLessonPlanProcedureStageModal: false,
    updateLessonPlanProcedureStageModal: false,

    importSchoolPayExcelModal: false,

    addIndisciplineCaseModal: false,
    updateIndisciplineCaseModal: false,
    indisciplineCaseDetailsModal: false,

    joinClassesModal: false,

    addCompetencyGradingScaleModal: false,
    updateCompetencyGradingScaleModal: false,

    downloadStaffTemplateModal: false,

    addSubjectCombinationModal: false,
    updateSubjectCombinationModal: false,
    subjectCombinationDetailsModal: false,
    uploadSubjectCombinationDocumentModal: false,
  },
};

export default initialState;
