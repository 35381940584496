const appUi = {
  ADD_NEW_APP: "ADD_NEW_APP",
  REMOVE_APP: "REMOVE_APP",
  SET_ACTIVE_APP: "SET_ACTIVE_APP",
  SET_ACTIVE_SUB_APP: "SET_ACTIVE_SUB_APP",
  SET_PREV_SUB_APP: "SET_PREV_SUB_APP",
  SET_OPEN_APPS: "SET_OPEN_APPS",

  TOGGLE_RECEIVABLES_LEDGER_MODAL: "TOGGLE_RECEIVABLES_LEDGER_MODAL",
  TOGGLE_LEARNER_RECEIVABLES_LEDGER_MODAL:
    "TOGGLE_LEARNER_RECEIVABLES_LEDGER_MODAL",
  TOGGLE_PREPAYMENTS_LEDGER_MODAL: "TOGGLE_PREPAYMENTS_LEDGER_MODAL",
  TOGGLE_LEARNER_PREPAYMENTS_LEDGER_MODAL:
    "TOGGLE_LEARNER_PREPAYMENTS_LEDGER_MODAL",

  TOGGLE_COPY_BUDGET_MODAL: "TOGGLE_COPY_BUDGET_MODAL",

  TOGGLE_ADD_TIMETABLE_MODAL: "TOGGLE_ADD_TIMETABLE_MODAL",
  TOGGLE_UPDATE_TIMETABLE_MODAL: "TOGGLE_UPDATE_TIMETABLE_MODAL",
  TOGGLE_TIMETABLE_DETAILS_MODAL: "TOGGLE_TIMETABLE_DETAILS_MODAL",

  TOGGLE_APP_DRAWER: "TOGGLE_APP_DRAWER",

  TOGGLE_PROMOTE_LEARNERS_MODAL: "TOGGLE_PROMOTE_LEARNERS_MODAL",
  TOGGLE_TRANSFER_LEARNERS_MODAL: "TOGGLE_TRANSFER_LEARNERS_MODAL",

  TOGGLE_SUB_APP_MENU: "TOGGLE_SUB_APP_MENU",

  TOGGLE_REPORTS_MODAL: "TOGGLE_PRINT_STUDENTS_REPORTS_MODAL",

  SEND_DEFUALTER_COMMUNICATION_MODAL: "SEND_DEFUALTER_COMMUNICATION_MODAL",

  TOGGLE_ADD_GENERAL_SCHOOL_COMMUNICATION_MODAL:
    "TOGGLE_ADD_GENERAL_SCHOOL_COMMUNICATION_MODAL",

  TOGGLE_LOAN_REPAYMENT_SCHEDULE_DETAILS_MODAL:
    "TOGGLE_LOAN_REPAYMENT_SCHEDULE_DETAILS_MODAL",
  TOGGLE_ADD_LOAN_PAYMENT_MODAL: "TOGGLE_ADD_LOAN_PAYMENT_MODAL",
  TOGGLE_ADD_LOAN_SCHEDULE_MODAL: "TOGGLE_ADD_LOAN_SCHEDULE_MODAL",

  TOGGLE_SET_CLASS_PERFORMANCE_ANALYSIS_DETAILS:
    "TOGGLE_SET_CLASS_PERFORMANCE_ANALYSIS_DETAILS",
  TOGGLE_SET_SUBJECT_PERFORMANCE_ANALYSIS_DETAILS:
    "TOGGLE_SET_SUBJECT_PERFORMANCE_ANALYSIS_DETAILS",

  TOGGLE_STUDENT_BILLING_FORM_MODAL: "TOGGLE_STUDENT_BILLING_FORM_MODAL",
  TOGGLE_SINGLE_BILLING_MODAL: "TOGGLE_SINGLE_BILLING_MODAL",

  TOGGLE_BULK_RESULTS_MODAL: "TOGGLE_BULK_RESULTS_MODAL",

  TOGGLE_SUBSCRIPTION_PAYMENTS_MODEL: "TOGGLE_SUBSCRIPTION_PAYMENTS_MODEL",

  TOGGLE_ADD_BRMS_COMPLIANCE_MODAL: "TOGGLE_ADD_BRMS_COMPLIANCE_MODAL",
  TOGGLE_UPDATE_BRMS_COMPLIANCE_MODAL: "TOGGLE_UPDATE_BRMS_COMPLIANCE_MODAL",
  TOGGLE_BRMS_COMPLIANCE_DETAILS_MODAL: "TOGGLE_BRMS_COMPLIANCE_DETAILS_MODAL",

  TOGGLE_ADD_ROLE_MODAL: "TOGGLE_ADD_ROLE_MODAL",
  TOGGLE_UPDATE_ROLES_MODAL: "TOGGLE_UPDATE_ROLES_MODAL",
  TOGGLE_ROLES_DETAILS_MODELS: "TOGGLE_ROLES_DETAILS_MODELS",

  TOGGLE_UPLOAD_INCOME_RECEIPT_EXCEL_MODAL:
    "TOGGLE_UPLOAD_INCOME_RECEIPT_EXCEL_MODAL",
  TOGGLE_DOWNLOAD_INCOME_RECEIPT_EXCEL_MODAL:
    "TOGGLE_DOWNLOAD_INCOME_RECEIPT_EXCEL_MODAL",

  TOGGLE_LEARNING_AREAS_MODAL: "TOGGLE_LEARNING_AREAS_MODAL",
  TOGGLE_SUPPLEMENTARIES_MODAL: "TOGGLE_SUPPLEMENTARIES_MODAL",

  TOGGLE_PRINT_TABLE_MODAL: "TOGGLE_PRINT_TABLE_MODAL",
  TOGGLE_PRINT_MODAL: "TOGGLE_PRINT_MODAL",

  TOGGLE_ADD_PERMISSIONS_MODAL: "TOGGLE_ADD_PERMISSIONS_MODAL",

  TOGGLE_ORGANISATION_LOGO_MODAL: "TOGGLE_ORGANISATION_LOGO_MODAL",

  TOGGLE_CUSTOM_BILLING_AMOUNT_MODAL: "TOGGLE_CUSTOM_BILLING_AMOUNT_MODAL",

  TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL:
    "TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL",
  TOGGLE_UPDATE_MANUALS_AND_POLICIES_MODAL:
    "TOGGLE_UPDATE_MANUALS_AND_POLICIES_MODAL",
  TOGGLE_MANUALS_AND_POLICIES_DETAILS_MODAL:
    "TOGGLE_MANUALS_AND_POLICIES_DETAILS_MODAL",

  TOGGLE_INCOME_STATEMENT: "TOGGLE_INCOME_STATEMENT",

  TOGGLE_REJECTIONS_MODAL: "TOGGLE_REJECTIONS_MODAL",

  TOGGLE_FORGOTPASSWORD_MODAL: "TOGGLE_FORGOTPASSWORD_MODAL",

  TOGGLE_ADD_ORGANISATION_USER_MODAL: "TOGGLE_ADD_ORGANISATION_USER_MODAL",
  TOGGLE_UPDATE_ORGANISATION_USERS_MODAL:
    "TOGGLE_UPDATE_ORGANISATION_USERS_MODAL",
  TOGGLE_ORGANISATION_USER_DETAILS_MODAL:
    "TOGGLE_ORGANISATION_USER_DETAILS_MODAL",

  TOGGLE_ADD_ACCOUNT_TYPES_MODAL: "TOGGLE_ADD_ACCOUNT_TYPES_MODAL",
  TOGGLE_UPDATE_ACCOUNT_TYPES_MODAL: "TOGGLE_UPDATE_ACCOUNT_TYPES_MODAL",
  TOGGLE_ACCOUNT_TYPE_DETAILS_MODAL: "TOGGLE_ACCOUNT_TYPE_DETAILS_MODAL",

  TOGGLE_ADD_ACCOUNT_MODAL: "TOGGLE_ADD_ACCOUNT_MODAL",
  TOGGLE_UPDATE_ACCOUNT_MODAL: "TOGGLE_UPDATE_ACCOUNT_MODAL",
  TOGGLE_ACCOUNT_DETAILS_MODAL: "TOGGLE_ACCOUNT_DETAILS_MODAL",

  TOGGLE_UPLOAD_USER_PROFILE_AVATAR_MODAL:
    "TOGGLE_UPLOAD_USER_PROFILE_AVATAR_MODAL",
  TOGGLE_UPLOAD_USER_SIGNATURE_MODAL: "TOGGLE_UPLOAD_USER_SIGNATURE_MODAL",

  TOGGLE_CHANGE_PASSWORD_MODAL: "TOGGLE_CHANGE_PASSWORD_MODAL",

  TOGGLE_ADD_FINANCE_STREAM_MODAL: "TOGGLE_ADD_FINANCE_STREAM_MODAL",
  TOGGLE_UPDATE_FINANCE_STREAM_MODAL: "TOGGLE_UPDATE_FINANCE_STREAM_MODAL",
  TOGGLE_FINANCE_STREAM_DETAILS_MODAL: "TOGGLE_FINANCE_STREAM_DETAILS_MODAL",

  TOGGLE_ADD_STUDENT_MODAL: "TOGGLE_ADD_STUDENT_MODAL",
  TOGGLE_GET_STUDENT_DETAILS_MODAL: "TOGGLE_GET_STUDENT_DETAILS_MODAL",
  TOGGLE_UPDATE_STUDENT_DETAILS_MODAL: "TOGGLE_UPDATE_STUDENT_DETAILS_MODAL",
  TOGGLE_ADD_BANKS_MODAL: "TOGGLE_ADD_BANKS_MODAL",
  TOGGLE_UPDATE_BANK_MODAL: "TOGGLE_UPDATE_BANK_MODAL",
  TOGGLE_BANK_DETAILS_MODAL: "TOGGLE_BANK_DETAILS_MODAL",

  TOGGLE_ADD_PARTNER_MODAL: "TOGGLE_ADD_PARTNER_MODAL",
  TOGGLE_UPDATE_PARTNER_MODAL: "TOGGLE_UPDATE_PARTNER_MODAL",
  TOGGLE_PARTNER_DETAILS_MODAL: "TOGGLE_PARTNER_DETAILS_MODAL",

  TOGGLE_ADD_BUDGETS_MODAL: "TOGGLE_ADD_BUDGETS_MODAL",
  TOGGLE_UPDATE_BUDGETS_MODAL: "TOGGLE_UPDATE_BUDGETS_MODAL",
  TOGGLE_BUDGET_DETAILS_PAGE: "TOGGLE_BUDGET_DETAILS_PAGE",

  TOGGLE_ADD_BUDGET_LINE_MODAL: "TOGGLE_ADD_BUDGET_LINE_MODAL",
  TOGGLE_UPDATE_BUDGET_LINE_MODAL: "TOGGLE_UPDATE_BUDGET_LINE_MODAL",
  TOGGLE_BUDGET_LINE_DETAILS_MODAL: "TOGGLE_BUDGET_LINE_DETAILS_MODAL",

  TOGGLE_UPDATE_BUDGET_STREAM_MODAL: "TOGGLE_UPDATE_BUDGET_STREAM_MODAL",
  TOGGLE_BUDGET_STREAM_DETAILS_MODAL: "TOGGLE_BUDGET_STREAM_DETAILS_MODAL",

  TOGGLE_ADD_ASSET_TYPE_MODAL: "TOGGLE_ADD_ASSET_TYPE_MODAL",
  TOGGLE_UPDATE_ASSET_TYPE_MODAL: "TOGGLE_UPDATE_ASSET_TYPE_MODAL",
  TOGGLE_ASSET_TYPE_DETAILS_MODAL: "TOGGLE_ASSET_TYPE_DETAILS_MODAL",

  TOGGLE_ADD_ASSET_MODAL: "TOGGLE_ADD_ASSET_MODAL",
  TOGGLE_UPDATE_ASSET_MODAL: "TOGGLE_UPDATE_ASSET_MODAL",
  TOGGLE_ASSET_DETAILS_MODAL: "TOGGLE_ASSET_DETAILS_MODAL",

  TOGGLE_ADD_INCOME_RECEIPT_MODAL: "TOGGLE_ADD_INCOME_RECEIPT_MODAL",
  TOGGLE_UPDATE_INCOME_RECEIPT_MODAL: "TOGGLE_UPDATE_INCOME_RECEIPT_MODAL",
  TOGGLE_INCOME_RECEIPT_DETAILS_MODAL: "TOGGLE_INCOME_RECEIPT_DETAILS_MODAL",

  TOGGLE_INCOME_RECEIPT_STREAM_DETAILS_MODAL:
    "TOGGLE_INCOME_RECEIPT_STREAM_DETAILS_MODAL",
  TOGGLE_ADD_INCOME_RECEIPT_STREAM_MODAL:
    "TOGGLE_ADD_INCOME_RECEIPT_STREAM_MODAL",
  TOGGLE_UPDATE_INCOME_RECEIPT_STREAM_MODAL:
    "TOGGLE_UPDATE_INCOME_RECEIPT_STREAM_MODAL",

  TOGGLE_ADD_INCOME_RECEIPT_DOCUMENT_MODAL:
    "TOGGLE_ADD_INCOME_RECEIPT_DOCUMENT_MODAL",

  TOGGLE_ADD_STORE_ITEM_MODAL: "TOGGLE_ADD_STORE_ITEM_MODAL",
  TOGGLE_UPDATE_STORE_ITEM_MODAL: "TOGGLE_UPDATE_STORE_ITEM_MODAL",
  TOGGLE_STORE_ITEM_DETAILS_MODAL: "TOGGLE_STORE_ITEM_DETAILS_MODAL",

  TOGGLE_ADD_STORE_ISSUE_MODAL: "TOGGLE_ADD_STORE_ISSUE_MODAL",
  TOGGLE_UPDATE_STORE_ISSUE_MODAL: "TOGGLE_UPDATE_STORE_ISSUE_MODAL",
  TOGGLE_STORE_ISSUE_DETAILS_MODAL: "TOGGLE_STORE_ISSUE_DETAILS_MODAL",

  TOGGLE_ADD_REQUISITION_MEMO_MODAL: "TOGGLE_ADD_REQUISITION_MEMO_MODAL",
  TOGGLE_UPDATE_REQUISITION_MEMO_MODAL: "TOGGLE_UPDATE_REQUISITION_MEMO_MODAL",
  TOGGLE_REQUISITION_MEMO_DETAILS_MODAL:
    "TOGGLE_REQUISITION_MEMO_DETAILS_MODAL",

  TOGGLE_ADD_REQUISITION_MEMO_COST_MODAL:
    "TOGGLE_ADD_REQUISITION_MEMO_COST_MODAL",
  TOGGLE_UPDATE_REQUISITION_MEMO_COST_MODAL:
    "TOGGLE_UPDATE_REQUISITION_MEMO_COST_MODAL",
  TOGGLE_REQUISITION_MEMO_COST_DETAILS_MODAL:
    "TOGGLE_REQUISITION_MEMO_COST_DETAILS_MODAL",

  TOGGLE_ADD_PAYMENT_VOUCHER_MODAL: "TOGGLE_ADD_PAYMENT_VOUCHER_MODAL",
  TOGGLE_UPDATE_PAYMENT_VOUCHER_MODAL: "TOGGLE_UPDATE_PAYMENT_VOUCHER_MODAL",
  TOGGLE_PAYMENT_VOUCHER_DETAILS_MODAL: "TOGGLE_PAYMENT_VOUCHER_DETAILS_MODAL",

  TOGGLE_ADD_PROJECT_MODAL: "TOGGLE_ADD_PROJECT_MODAL",
  TOGGLE_UPDATE_PROJECT_MODAL: "TOGGLE_UPDATE_PROJECT_MODAL",
  TOGGLE_PROJECT_DETAILS_MODAL: "TOGGLE_PROJECT_DETAILS_MODAL",

  TOGGLE_ADD_REQUEST_FOR_QUOTATION_MODAL:
    "TOGGLE_ADD_REQUEST_FOR_QUOTATION_MODAL",
  TOGGLE_UPDATE_REQUEST_FOR_QUOTATION_MODAL:
    "TOGGLE_UPDATE_REQUEST_FOR_QUOTATION_MODAL",
  TOGGLE_REQUEST_FOR_QUOTATION_DETAILS_MODAL:
    "TOGGLE_REQUEST_FOR_QUOTATION_DETAILS_MODAL",

  TOGGLE_ADD_BID_EVALUATION_MODAL: "TOGGLE_ADD_BID_EVALUATION_MODAL",
  TOGGLE_UPDATE_BID_EVALUATION_MODAL: "TOGGLE_UPDATE_BID_EVALUATION_MODAL",
  TOGGLE_BID_EVALUATION_DETAILS_MODAL: "TOGGLE_BID_EVALUATION_DETAILS_MODAL",

  TOGGLE_ADD_ELIGIBILITY_EVALUATION_MODAL:
    "TOGGLE_ADD_ELIGIBILITY_EVALUATION_MODAL",
  TOGGLE_UPDATE_ELIGIBILITY_EVALUATION_MODAL:
    "TOGGLE_UPDATE_ELIGIBILITY_EVALUATION_MODAL",
  TOGGLE_ELIGIBILITY_EVALUATION_DETAILS_MODAL:
    "TOGGLE_ELIGIBILITY_EVALUATION_DETAILS_MODAL",

  TOGGLE_ADD_TECHNICAL_EVALUATION_MODAL:
    "TOGGLE_ADD_TECHNICAL_EVALUATION_MODAL",
  TOGGLE_UPDATE_TECHNICAL_EVALUATION_MODAL:
    "TOGGLE_UPDATE_TECHNICAL_EVALUATION_MODAL",
  TOGGLE_TECHNICAL_EVALUATION_DETAILS_MODAL:
    "TOGGLE_TECHNICAL_EVALUATION_DETAILS_MODAL",

  TOGGLE_ADD_FINANCIAL_EVALUATION_MODAL:
    "TOGGLE_ADD_FINANCIAL_EVALUATION_MODAL",
  TOGGLE_UPDATE_FINANCIAL_EVALUATION_MODAL:
    "TOGGLE_UPDATE_FINANCIAL_EVALUATION_MODAL",
  TOGGLE_FINANCIAL_EVALUATION_DETAILS_MODAL:
    "TOGGLE_FINANCIAL_EVALUATION_DETAILS_MODAL",

  TOGGLE_ADD_LOCAL_PURCHASE_ORDER_MODAL:
    "TOGGLE_ADD_LOCAL_PURCHASE_ORDER_MODAL",
  TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_MODAL:
    "TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_MODAL",
  TOGGLE_LOCAL_PURCHASE_ORDER_DETAILS_MODAL:
    "TOGGLE_LOCAL_PURCHASE_ORDER_DETAILS_MODAL",

  TOGGLE_ADD_LOCAL_PURCHASE_ORDER_ITEM_MODAL:
    "TOGGLE_ADD_LOCAL_PURCHASE_ORDER_ITEM_MODAL",
  TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_MODAL:
    "TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_MODAL",
  TOGGLE_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_MODAL:
    "TOGGLE_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_MODAL",

  TOGGLE_ADD_CLASS_MODAL: "TOGGLE_ADD_CLASS_MODAL",
  TOGGLE_UPDATE_CLASS_MODAL: "TOGGLE_UPDATE_CLASS_MODAL",
  TOGGLE_CLASS_DETAILS_MODAL: "TOGGLE_CLASS_DETAILS_MODAL",

  TOGGLE_ADD_NOTICE_OF_BEST_BIDDER_MODAL:
    "TOGGLE_ADD_NOTICE_OF_BEST_BIDDER_MODAL",
  TOGGLE_UPDATE_NOTICE_OF_BEST_BIDDER_MODAL:
    "TOGGLE_UPDATE_NOTICE_OF_BEST_BIDDER_MODAL",
  TOGGLE_NOTICE_OF_BEST_BIDDER_DETAILS_MODAL:
    "TOGGLE_NOTICE_OF_BEST_BIDDER_DETAILS_MODAL",

  TOGGLE_ADD_ACADEMIC_YEAR_MODAL: "TOGGLE_ADD_ACADEMIC_YEAR_MODAL",
  TOGGLE_UPDATE_ACADEMIC_YEAR_MODAL: "TOGGLE_UPDATE_ACADEMIC_YEAR_MODAL",
  TOGGLE_ACADEMIC_YEARS_DETAILS_MODAL: "TOGGLE_ACADEMIC_YEARS_DETAILS_MODAL",
  TOGGLE_TERM_DETAILS_MODAL: "TOGGLE_TERM_DETAILS_MODAL",

  TOGGLE_PAYMENT_STRUCTURE_DETAILS_MODAL:
    "TOGGLE_PAYMENT_STRUCTURE_DETAILS_MODAL",
  TOGGLE_ADD_PAYMENT_STRUCTURES_MODAL: "TOGGLE_ADD_PAYMENT_STRUCTURES_MODAL",
  TOGGLE_UPDATE_PAYMENT_STRUCTURE_MODAL:
    "TOGGLE_UPDATE_PAYMENT_STRUCTURE_MODAL",

  TOGGLE_UPDATE_PAYMENT_STRUCTURE_COST_MODAL:
    "TOGGLE_UPDATE_PAYMENT_STRUCTURE_COST_MODAL",
  TOGGLE_PAYMENT_STRUCTURE_COST_DETAILS_MODAL:
    "TOGGLE_PAYMENT_STRUCTURE_COST_DETAILS_MODAL",

  TOGGLE_ADD_ADVANCE_ACCOUNTABILITY_FORMS_MODAL:
    "TOGGLE_ADD_ADVANCE_ACCOUNTABILITY_FORMS_MODAL",
  TOGGLE_UPDATE_ADVANCE_ACCOUNTABILITY_FORMS_MODAL:
    "TOGGLE_UPDATE_ADVANCE_ACCOUNTABILITY_FORMS_MODAL",
  TOGGLE_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_MODAL:
    "TOGGLE_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_MODAL",

  TOGGLE_ADD_ACTUAL_EXPENDITURE_MODAL: "TOGGLE_ADD_ACTUAL_EXPENDITURE_MODAL",
  TOGGLE_UPDATE_ACTUAL_EXPENDITURE_MODAL:
    "TOGGLE_UPDATE_ACTUAL_EXPENDITURE_MODAL",
  TOGGLE_ACTUAL_EXPENDITURE_DETAILS_MODAL:
    "TOGGLE_ACTUAL_EXPENDITURE_DETAILS_MODAL",

  TOGGLE_ADD_ENROLLMENT_MODAL: "TOGGLE_ADD_ENROLLMENT_MODAL",
  TOGGLE_ENROLL_STUDENTS_MODAL: "TOGGLE_ENROLL_STUDENTS_MODAL",
  TOGGLE_ENROLLMENT_DETAILS_MODAL: "TOGGLE_ENROLLMENT_DETAILS_MODAL",

  TOGGLE_ADD_SUBJECT_CLASS_MODAL: "TOGGLE_ADD_SUBJECT_CLASS_MODAL",
  TOGGLE_UPDATE_SUBJECT_CLASS_MODAL: "TOGGLE_UPDATE_SUBJECT_CLASS_MODAL",
  TOGGLE_SUBJECT_CLASS_DETAILS_MODAL: "TOGGLE_SUBJECT_CLASS_DETAILS_MODAL",

  TOGGLE_ADD_TEACHER_ASSIGNMENT_MODAL: "TOGGLE_ADD_TEACHER_ASSIGNMENT_MODAL",
  TOGGLE_TEACHER_ASSIGNMENT_DETAILS_MODAL:
    "TOGGLE_TEACHER_ASSIGNMENT_DETAILS_MODAL",
  TOGGLE_UPDATE_TEACHER_ASSIGNMENT_MODAL:
    "TOGGLE_UPDATE_TEACHER_ASSIGNMENT_MODAL",

  TOGGLE_ADD_EXAMINATIONS_MODAL: "TOGGLE_ADD_EXAMINATIONS_MODAL",
  TOGGLE_UPDATE_EXAMINATIONS_MODAL: "TOGGLE_UPDATE_EXAMINATIONS_MODAL",
  TOGGLE_EXAMINATIONS_DETAILS_MODAL: "TOGGLE_EXAMINATIONS_DETAILS_MODAL",

  TOGGLE_ADD_OPTIONAL_SUBJECT_MODAL: "TOGGLE_ADD_OPTIONAL_SUBJECT_MODAL",
  TOGGLE_ADD_OPTIONAL_SUBJECT_STUDENTS_MODAL:
    "TOGGLE_ADD_OPTIONAL_SUBJECT_STUDENTS_MODAL",
  TOGGLE_OPTIONAL_SUBJECT_DETAILS_MODAL:
    "TOGGLE_OPTIONAL_SUBJECT_DETAILS_MODAL",

  TOGGLE_ADD_SUBJECT_MODAL: "TOGGLE_ADD_SUBJECT_MODAL",
  TOGGLE_UPDATE_SUBJECT_MODAL: "TOGGLE_UPDATE_SUBJECT_MODAL",
  TOGGLE_SUBJECT_DETAILS_MODAL: "TOGGLE_SUBJECT_DETAILS_MODAL",

  TOGGLE_ADD_RECORD_OF_BIDDERS_MODAL: "TOGGLE_ADD_RECORD_OF_BIDDERS_MODAL",
  TOGGLE_UPDATE_RECORD_OF_BIDDERS_MODAL:
    "TOGGLE_UPDATE_RECORD_OF_BIDDERS_MODAL",
  TOGGLE_RECORD_OF_BIDDERS_DETAILS_MODAL:
    "TOGGLE_RECORD_OF_BIDDERS_DETAILS_MODAL",

  TOGGLE_ADD_BIDDER_MODAL: "TOGGLE_ADD_BIDDER_MODAL",
  TOGGLE_UPDATE_BIDDER_MODAL: "TOGGLE_UPDATE_BIDDER_MODAL",
  TOGGLE_BIDDER_DETAILS_MODAL: "TOGGLE_BIDDER_DETAILS_MODAL",

  TOGGLE_ADD_STREAMS_MODAL: "TOGGLE_ADD_STREAMS_MODAL",
  TOGGLE_UPDATE_STREAM_MODAL: "TOGGLE_UPDATE_STREAM_MODAL",
  TOGGLE_STREAM_DETAILS_MODAL: "TOGGLE_STREAM_DETAILS_MODAL",

  TOGGLE_ADD_GRADING_MODAL: "TOGGLE_ADD_GRADING_MODAL",
  TOGGLE_UPDATE_GRADING_MODAL: "TOGGLE_UPDATE_GRADING_MODAL",
  TOGGLE_GRADING_DETAILS_MODAL: "TOGGLE_GRADING_DETAILS_MODAL",

  TOGGLE_UPLOAD_STUDENT_DOCUMENTS_MODAL:
    "TOGGLE_UPLOAD_STUDENT_DOCUMENTS_MODAL",

  TOGGLE_ADD_BURSARIES_MODAL: "TOGGLE_ADD_BURSARIES_MODAL",
  TOGGLE_UPDATE_BURSARIES_MODAL: "TOGGLE_UPDATE_BURSARIES_MODAL",
  TOGGLE_BURSARIES_DETAILS_MODAL: "TOGGLE_BURSARIES_DETAILS_MODAL",

  TOGGLE_UPDATE_BURSARY_COST_MODAL: "TOGGLE_UPDATE_BURSARY_COST_MODAL",
  TOGGLE_BURSARY_COST_DETAILS_MODAL: "TOGGLE_BURSARY_COST_DETAILS_MODAL",

  TOGGLE_ADD_LEDGER_MODAL: "TOGGLE_ADD_LEDGER_MODAL",
  TOGGLE_UPDATE_LEDGER_MODAL: "TOGGLE_UPDATE_LEDGER_MODAL",
  TOGGLE_LEDGER_DETAILS_MODAL: "TOGGLE_LEDGER_DETAILS_MODAL",
  TOGGLE_LEDGER_ACCOUNT_DETAILS_MODAL: "TOGGLE_LEDGER_ACCOUNT_DETAILS_MODAL",

  TOGGLE_ADD_MARKS_UPLOAD_MODAL: "TOGGLE_ADD_MARKS_UPLOAD_MODAL",
  TOGGLE_UPDATE_MARKS_UPLOAD_MODAL: "TOGGLE_UPDATE_MARKS_UPLOAD_MODAL",
  TOGGLE_MARKS_UPLOAD_DETAILS_MODAL: "TOGGLE_MARKS_UPLOAD_DETAILS_MODAL",
  TOGGLE_MARKS_UPLOAD_TABLE: "TOGGLE_MARKS_UPLOAD_TABLE",
  TOGGLE_ADD_STUDENT_MARKS_MODAL: "TOGGLE_ADD_STUDENT_MARKS_MODAL",
  TOGGLE_UPDATE_STUDENT_MARKS_MODAL: "TOGGLE_UPDATE_STUDENT_MARKS_MODAL",

  TOGGLE_ADD_ALEVEL_GRADING_MODAL: "TOGGLE_ADD_ALEVEL_GRADING_MODAL",
  TOGGLE_UPDATE_ALEVEL_GRADING_MODAL: "TOGGLE_UPDATE_ALEVEL_GRADING_MODAL",

  TOGGLE_ADD_STUDENT_REPORTS_MODAL: "TOGGLE_ADD_STUDENT_REPORTS_MODAL",
  TOGGLE_UPDATE_STUDENT_REPORTS_MODAL: "TOGGLE_UPDATE_STUDENT_REPORTS_MODAL",
  TOGGLE_STUDENT_REPORTS_DETAILS_MODAL: "TOGGLE_STUDENT_REPORTS_DETAILS_MODAL",

  TOGGLE_PRINT_SUBJECTS_TABLE_MODAL: "TOGGLE_PRINT_SUBJECTS_TABLE_MODAL",
  TOGGLE_PRINT_STUDENTS_TABLE_MODAL: "TOGGLE_PRINT_STUDENTS_TABLE_MODAL",
  TOGGLE_PRINT_OPTIONAL_SUBJECTS_TABLE_MODAL:
    "TOGGLE_PRINT_OPTIONAL_SUBJECTS_TABLE_MODAL",
  TOGGLE_PRINT_ENROLLMENT_TABLE_MODAL: "TOGGLE_PRINT_ENROLLMENT_TABLE_MODAL",

  SET_PRINT_DATA: "SET_PRINT_DATA",

  TOGGLE_ADD_STAFF_DEPENDANTS_MODAL: "TOGGLE_ADD_STAFF_DEPENDANTS_MODAL",
  TOGGLE_UPDATE_STAFF_DEPENDANTS_MODAL: "TOGGLE_UPDATE_STAFF_DEPENDANTS_MODAL",

  TOGGLE_UPLOAD_STAFF_DOCUMENTS_MODAL: "TOGGLE_UPLOAD_STAFF_DOCUMENTS_MODAL",
  TOGGLE_UPDATE_STAFF_DOCUMENTS_MODAL: "TOGGLE_UPDATE_STAFF_DOCUMENTS_MODAL",

  TOGGLE_DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL:
    "TOGGLE_DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL",
  TOGGLE_UPLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL:
    "TOGGLE_UPLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL",

  TOGGLE_UPDATE_ENROLLMENT_MODAL: "TOGGLE_UPDATE_ENROLLMENT_MODAL",

  TOGGLE_ADD_STAFF_QUALIFICATION_MODAL: "TOGGLE_ADD_STAFF_QUALIFICATION_MODAL",
  TOGGLE_UPDATE_STAFF_QUALIFICATION_MODAL:
    "TOGGLE_UPDATE_STAFF_QUALIFICATION_MODAL",
  TOGGLE_STAFF_QUALIFICATION_DETAILS_MODAL:
    "TOGGLE_STAFF_QUALIFICATION_DETAILS_MODAL",

  TOGGLE_BANK_STATEMENT_MODAL: "TOGGLE_BANK_STATEMENT_MODAL",

  TOGGLE_SEARCH_STUDENTS_MODAL: "TOGGLE_SEARCH_STUDENTS_MODAL",

  TOGGLE_SORT_INCOME_RECEIPTS_MODAL: "TOGGLE_SORT_INCOME_RECEIPTS_MODAL",

  TOGGLE_UPDATE_USER_EMAIL_MODAL: "TOGGLE_UPDATE_USER_EMAIL_MODAL",

  TOGGLE_UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_MODAL:
    "TOGGLE_UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_MODAL",

  TOGGLE_SORT_REQUISITION_MEMOS_MODAL: "TOGGLE_SORT_REQUISITION_MEMOS_MODAL",

  TOGGLE_SORT_ADVANCE_ACCOUNTABILITY_MODAL:
    "TOGGLE_SORT_ADVANCE_ACCOUNTABILITY_MODAL",

  TOGGLE_SORT_MODAL: "TOGGLE_SORT_MODAL",

  TOGGLE_SORT_FROM_TABLE_MODAL: "TOGGLE_SORT_FROM_TABLE_MODAL",

  TOGGLE_ADD_DIVISIONS_MODAL: "TOGGLE_ADD_DIVISIONS_MODAL",
  TOGGLE_UPDATE_DIVISION_MODAL: "TOGGLE_UPDATE_DIVISION_MODAL",

  TOGGLE_FEES_DEFAULTER_DETAILS_MODAL: "TOGGLE_FEES_DEFAULTER_DETAILS_MODAL",

  TOGGLE_REPORT_COMMENTS_MODAL: "TOGGLE_REPORT_COMMENTS_MODAL",

  TOGGLE_MANDATORY_BILLING_MODAL: "TOGGLE_MANDATORY_BILLING_MODAL",
  TOGGLE_BILL_LEARNERS_MODAL: "TOGGLE_BILL_LEARNERS_MODAL",

  TOGGLE_CREATE_REPORT_EXCEPTION_MODAL: "TOGGLE_CREATE_REPORT_EXCEPTION_MODAL",
  TOGGLE_UPDATE_REPORT_EXCEPTION_MODAL: "TOGGLE_UPDATE_REPORT_EXCEPTION_MODAL",
  TOGGLE_ADD_SINGLE_REPORT_EXCEPTION_MODAL:
    "TOGGLE_ADD_SINGLE_REPORT_EXCEPTION_MODAL",

  TOGGLE_ADD_SUPPLIER_PAYMENT_SCHEDULE_MODAL:
    "TOGGLE_ADD_SUPPLIER_PAYMENT_SCHEDULE_MODAL",
  TOGGLE_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_MODAL:
    "TOGGLE_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_MODAL",
  TOGGLE_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_MODAL:
    "TOGGLE_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_MODAL",

  TOGGLE_ADD_SUPPLIER_PAYMENT_MODAL: "TOGGLE_ADD_SUPPLIER_PAYMENT_MODAL",
  TOGGLE_UPDATE_SUPPLIER_PAYMENT_MODAL: "TOGGLE_UPDATE_SUPPLIER_PAYMENT_MODAL",
  TOGGLE_SUPPLIER_PAYMENT_DETAILS_MODAL:
    "TOGGLE_SUPPLIER_PAYMENT_DETAILS_MODAL",

  TOGGLE_ADD_LEARNER_ATTENDANCE_MODAL: "TOGGLE_ADD_LEARNER_ATTENDANCE_MODAL",
  TOGGLE_UPDATE_LEARNER_ATTENDANCE_MODAL:
    "TOGGLE_UPDATE_LEARNER_ATTENDANCE_MODAL",
  TOGGLE_LEARNER_ATTENDANCE_DETAILS_MODAL:
    "TOGGLE_LEARNER_ATTENDANCE_DETAILS_MODAL",

  TOGGLE_ADD_STAFF_ATTENDANCE_MODAL: "TOGGLE_ADD_STAFF_ATTENDANCE_MODAL",
  TOGGLE_UPDATE_STAFF_ATTENDANCE_MODAL: "TOGGLE_UPDATE_STAFF_ATTENDANCE_MODAL",
  TOGGLE_STAFF_ATTENDANCE_DETAILS_MODAL:
    "TOGGLE_STAFF_ATTENDANCE_DETAILS_MODAL",

  TOGGLE_ADD_SUPPLIER_PAYMENT_INVOICE_MODAL:
    "TOGGLE_ADD_SUPPLIER_PAYMENT_INVOICE_MODAL",
  TOGGLE_UPDATE_SUPPLIER_PAYMENT_INVOICE_MODAL:
    "TOGGLE_UPDATE_SUPPLIER_PAYMENT_INVOICE_MODAL",
  TOGGLE_ADD_GENERAL_SUPPLIER_PAYMENT_INVOICE_MODAL:
    "TOGGLE_ADD_GENERAL_SUPPLIER_PAYMENT_INVOICE_MODAL",

  SYNC_SCHOOL_PAY_TRANSACTIONS_MODAL: "SYNC_SCHOOL_PAY_TRANSACTIONS_MODAL",

  SCHOOL_COMMUNICATIONS_DETAILS_MODAL: "SCHOOL_COMMUNICATIONS_DETAILS_MODAL",

  TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_MODAL:
    "TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_MODAL",
  TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_MODAL:
    "TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_MODAL",
  TOGGLE_SCHOOL_DEVELOPMENT_PLAN_DETAILS_MODAL:
    "TOGGLE_SCHOOL_DEVELOPMENT_PLAN_DETAILS_MODAL",

  TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL:
    "TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL",
  TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL:
    "TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL",
  TOGGLE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DETAILS_MODAL:
    "TOGGLE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DETAILS_MODAL",

  TOGGLE_ADD_STAFF_PAYROLL_MODAL: "TOGGLE_ADD_STAFF_PAYROLL_MODAL",
  TOGGLE_UPDATE_STAFF_PAYROLL_MODAL: "TOGGLE_UPDATE_STAFF_PAYROLL_MODAL",
  TOGGLE_STAFF_PAYROLL_DETAILS_MODAL: "TOGGLE_STAFF_PAYROLL_DETAILS_MODAL",

  TOGGLE_ADD_PARENT_CHART_OF_ACCOUNTS_MODAL:
    "TOGGLE_ADD_PARENT_CHART_OF_ACCOUNTS_MODAL",

  TOGGLE_ADD_CLIENT_INVOICE_MODAL: "TOGGLE_ADD_CLIENT_INVOICE_MODAL",
  TOGGLE_UPDATE_CLIENT_INVOICE_MODAL: "TOGGLE_UPDATE_CLIENT_INVOICE_MODAL",
  TOGGLE_CLIENT_INVOICE_DETAILS_MODAL: "TOGGLE_CLIENT_INVOICE_DETAILS_MODAL",

  TOGGLE_INCOME_STATEMENT_ACCOUNT_DETAILS_MODAL:
    "TOGGLE_INCOME_STATEMENT_ACCOUNT_DETAILS_MODAL",

  TOGGLE_DOCUMENTS_VIEWER_MODAL: "TOGGLE_DOCUMENTS_VIEWER_MODAL",

  TOGGLE_ADD_SCHOOL_GATE_PASS_MODAL: "TOGGLE_ADD_SCHOOL_GATE_PASS_MODAL",
  TOGGLE_UPDATE_SCHOOL_GATE_PASS_MODAL: "TOGGLE_UPDATE_SCHOOL_GATE_PASS_MODAL",
  TOGGLE_SCHOOL_GATE_PASS_DETAILS_MODAL:
    "TOGGLE_SCHOOL_GATE_PASS_DETAILS_MODAL",

  TOGGLE_ADD_SCHOOL_TIME_TABLE_MODAL: "TOGGLE_ADD_SCHOOL_TIME_TABLE_MODAL",
  TOGGLE_UPDATE_SCHOOL_TIME_TABLE_MODAL:
    "TOGGLE_UPDATE_SCHOOL_TIME_TABLE_MODAL",
  TOGGLE_SCHOOL_TIME_TABLE_DETAILS_MODAL:
    "TOGGLE_SCHOOL_TIME_TABLE_DETAILS_MODAL",

  TOGGLE_ADD_SCHOOL_TIME_TABLE_ACTIVITY_MODAL:
    "TOGGLE_ADD_SCHOOL_TIME_TABLE_ACTIVITY_MODAL",
  TOGGLE_UPDATE_SCHOOL_TIME_TABLE_ACTIVITY_MODAL:
    "TOGGLE_UPDATE_SCHOOL_TIME_TABLE_ACTIVITY_MODAL",

  TOGGLE_ADD_SCHEME_OF_WORK_MODAL: "TOGGLE_ADD_SCHEME_OF_WORK_MODAL",
  TOGGLE_UPDATE_SCHEME_OF_WORK_MODAL: "TOGGLE_UPDATE_SCHEME_OF_WORK_MODAL",
  TOGGLE_SCHEME_OF_WORK_DETAILS_MODAL: "TOGGLE_SCHEME_OF_WORK_DETAILS_MODAL",

  TOGGLE_ADD_SCHEME_OF_WORK_ITEM_MODAL: "TOGGLE_ADD_SCHEME_OF_WORK_ITEM_MODAL",
  TOGGLE_UPDATE_SCHEME_OF_WORK_ITEM_MODAL:
    "TOGGLE_UPDATE_SCHEME_OF_WORK_ITEM_MODAL",
  TOGGLE_SCHEME_OF_WORK_ITEM_DETAILS_MODAL:
    "TOGGLE_SCHEME_OF_WORK_ITEM_DETAILS_MODAL",

  TOGGLE_ADD_LESSON_PLAN_MODAL: "TOGGLE_ADD_LESSON_PLAN_MODAL",
  TOGGLE_UPDATE_LESSON_PLAN_MODAL: "TOGGLE_UPDATE_LESSON_PLAN_MODAL",
  TOGGLE_LESSON_PLAN_DETAILS_MODAL: "TOGGLE_LESSON_PLAN_DETAILS_MODAL",

  TOGGLE_ADD_LESSON_PLAN_PROCEDURE_STAGE_MODAL:
    "TOGGLE_ADD_LESSON_PLAN_PROCEDURE_STAGE_MODAL",
  TOGGLE_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_MODAL:
    "TOGGLE_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_MODAL",

  TOGGLE_IMPORT_SCHOOL_PAY_EXCEL_MODAL: "TOGGLE_IMPORT_SCHOOL_PAY_EXCEL_MODAL",

  TOGGLE_ADD_INDISCIPLINE_CASE_MODAL: "TOGGLE_ADD_INDISCIPLINE_CASE_MODAL",
  TOGGLE_UPDATE_INDISCIPLINE_CASE_MODAL:
    "TOGGLE_UPDATE_INDISCIPLINE_CASE_MODAL",
  TOGGLE_INDISCIPLINE_CASE_DETAILS_MODAL:
    "TOGGLE_INDISCIPLINE_CASE_DETAILS_MODAL",

  TOGGLE_JOIN_CLASSESS_MODAL: "TOGGLE_JOIN_CLASSESS_MODAL",

  TOGGLE_ADD_COMPETENCY_GRADING_SCALE_MODAL:
    "TOGGLE_ADD_COMPETENCY_GRADING_SCALE_MODAL",
  TOGGLE_UPDATE_COMPETENCY_GRADING_SCALE_MODAL:
    "TOGGLE_UPDATE_COMPETENCY_GRADING_SCALE_MODAL",

  TOGGLE_DOWNLOAD_STAFF_UPLOAD_TEMPLATE_MODAL:
    "TOGGLE_DOWNLOAD_STAFF_UPLOAD_TEMPLATE_MODAL",

  TOGGLE_ADD_SUBJECT_COMBINATION_MODAL: "TOGGLE_ADD_SUBJECT_COMBINATION_MODAL",
  TOGGLE_UPDATE_SUBJECT_COMBINATION_MODAL:
    "TOGGLE_UPDATE_SUBJECT_COMBINATION_MODAL",
  TOGGLE_SUBJECT_COMBINATION_DETAILS_MODAL:
    "TOGGLE_SUBJECT_COMBINATION_DETAILS_MODAL",
  TOGGLE_UPLOAD_SUBJECT_COMBINATION_DOCUMENT_MODAL:
    "TOGGLE_UPLOAD_SUBJECT_COMBINATION_DOCUMENT_MODAL",

  setOpenApps: (data) => ({
    type: appUi.SET_OPEN_APPS,
    data,
  }),
  toggleDownloadStaffUploadTemplate: (show) => ({
    type: appUi.TOGGLE_DOWNLOAD_STAFF_UPLOAD_TEMPLATE_MODAL,
    show,
  }),
  toggleAddCompetencyGradingScaleModal: (data) => ({
    type: appUi.TOGGLE_ADD_COMPETENCY_GRADING_SCALE_MODAL,
    data,
  }),
  toggleUpdateCompetencyGradingScaleModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_COMPETENCY_GRADING_SCALE_MODAL,
    data,
  }),
  toggleJoinClassessModal: (data) => ({
    type: appUi.TOGGLE_JOIN_CLASSESS_MODAL,
    data,
  }),
  toggleAddSchemeOfWork: (data) => ({
    type: appUi.TOGGLE_ADD_SCHEME_OF_WORK_MODAL,
    data,
  }),
  toggleImportSchoolPayExcelModal: (data) => ({
    type: appUi.TOGGLE_IMPORT_SCHOOL_PAY_EXCEL_MODAL,
    data,
  }),
  toggleUpdateSchemeOfWork: (data) => ({
    type: appUi.TOGGLE_UPDATE_SCHEME_OF_WORK_MODAL,
    data,
  }),
  toggleSchemeOfWorkDetails: (data) => ({
    type: appUi.TOGGLE_SCHEME_OF_WORK_DETAILS_MODAL,
    data,
  }),
  toggleAddSchemeOfWorkItem: (data) => ({
    type: appUi.TOGGLE_ADD_SCHEME_OF_WORK_ITEM_MODAL,
    data,
  }),
  toggleUpdateSchemeOfWorkItem: (data) => ({
    type: appUi.TOGGLE_UPDATE_SCHEME_OF_WORK_ITEM_MODAL,
    data,
  }),
  toggleSchemeOfWorkItemDetails: (data) => ({
    type: appUi.TOGGLE_SCHEME_OF_WORK_ITEM_DETAILS_MODAL,
    data,
  }),

  toggleAddTimetableModal: (data) => ({
    type: appUi.TOGGLE_ADD_TIMETABLE_MODAL,
    data,
  }),
  toggleUpdateTimetableModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_TIMETABLE_MODAL,
    data,
  }),
  toggleTimetableDetails: (data) => ({
    type: appUi.TOGGLE_TIMETABLE_DETAILS_MODAL,
    data,
  }),
  toggleCopyBudgetModal: (data) => ({
    type: appUi.TOGGLE_COPY_BUDGET_MODAL,
    data,
  }),
  toggleDocumentsViewerModal: (data) => ({
    type: appUi.TOGGLE_DOCUMENTS_VIEWER_MODAL,
    data,
  }),
  toggleTransferLearnersModal: (data) => ({
    type: appUi.TOGGLE_TRANSFER_LEARNERS_MODAL,
    data,
  }),
  togglePromoteLearnersModal: (data) => ({
    type: appUi.TOGGLE_PROMOTE_LEARNERS_MODAL,
    data,
  }),
  toggleAddBrmsComplianceModal: (data) => ({
    type: appUi.TOGGLE_ADD_BRMS_COMPLIANCE_MODAL,
    data,
  }),
  toggleUpdateBrmsComplianceModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BRMS_COMPLIANCE_MODAL,
    data,
  }),
  toggleBrmsComplianceDetailsModal: (data) => ({
    type: appUi.TOGGLE_BRMS_COMPLIANCE_DETAILS_MODAL,
    data,
  }),
  toggleReceivablesLedgerModal: (data) => ({
    type: appUi.TOGGLE_RECEIVABLES_LEDGER_MODAL,
    data,
  }),
  toggleLearnerReceivablesLedgerModal: (data) => ({
    type: appUi.TOGGLE_LEARNER_RECEIVABLES_LEDGER_MODAL,
    data,
  }),
  togglePrepaymentsLedgerModal: (data) => ({
    type: appUi.TOGGLE_PREPAYMENTS_LEDGER_MODAL,
    data,
  }),
  toggleLearnerPrepaymentsLedgerModal: (data) => ({
    type: appUi.TOGGLE_LEARNER_PREPAYMENTS_LEDGER_MODAL,
    data,
  }),
  toggleAddParentChartOfAccountsModal: (data) => ({
    type: appUi.TOGGLE_ADD_PARENT_CHART_OF_ACCOUNTS_MODAL,
    data,
  }),
  toggleSendDefaulterCommunicationModal: (data) => ({
    type: appUi.SEND_DEFUALTER_COMMUNICATION_MODAL,
    data,
  }),
  toggleSchoolCommunicationsDetailsModal: (data) => ({
    type: appUi.SCHOOL_COMMUNICATIONS_DETAILS_MODAL,
    data,
  }),
  toggleAddGeneralSchoolCommunicationsModal: (data) => ({
    type: appUi.TOGGLE_ADD_GENERAL_SCHOOL_COMMUNICATION_MODAL,
    data,
  }),
  toggleSyncSchoolPayTransactionsModal: (data) => ({
    type: appUi.SYNC_SCHOOL_PAY_TRANSACTIONS_MODAL,
    data,
  }),
  toggleCustomBillingAmountModal: (data) => ({
    type: appUi.TOGGLE_CUSTOM_BILLING_AMOUNT_MODAL,
    data,
  }),
  toggleAddLoanPaymentModal: (data) => ({
    type: appUi.TOGGLE_ADD_LOAN_PAYMENT_MODAL,
    data,
  }),
  toggleAddLoanScheduleModal: (data) => ({
    type: appUi.TOGGLE_ADD_LOAN_SCHEDULE_MODAL,
    data,
  }),
  toggleLoanScheduleDetailsModal: (data) => ({
    type: appUi.TOGGLE_LOAN_REPAYMENT_SCHEDULE_DETAILS_MODAL,
    data,
  }),
  toggleClassPerformanceAnalysis: (data) => ({
    type: appUi.TOGGLE_SET_CLASS_PERFORMANCE_ANALYSIS_DETAILS,
    data,
  }),
  toggleSubjectPerformanceAnalysis: (data) => ({
    type: appUi.TOGGLE_SET_SUBJECT_PERFORMANCE_ANALYSIS_DETAILS,
    data,
  }),
  toggleSubAppMenu: (data) => ({
    type: appUi.TOGGLE_SUB_APP_MENU,
    data,
  }),
  toggleSingleBillingModal: (data) => ({
    type: appUi.TOGGLE_SINGLE_BILLING_MODAL,
    data,
  }),
  toggleStudentBillingModal: (data) => ({
    type: appUi.TOGGLE_STUDENT_BILLING_FORM_MODAL,
    data,
  }),
  toggleBulkPrintResultsModal: (data) => ({
    type: appUi.TOGGLE_BULK_RESULTS_MODAL,
    data,
  }),
  toggleUploadIncomeReceiptModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_INCOME_RECEIPT_EXCEL_MODAL,
    data,
  }),
  toggleDownloadIncomeReceiptModal: (data) => ({
    type: appUi.TOGGLE_DOWNLOAD_INCOME_RECEIPT_EXCEL_MODAL,
    data,
  }),
  setPrintData: (data) => ({
    type: appUi.SET_PRINT_DATA,
    data,
  }),
  togglePrintModal: (data) => ({
    type: appUi.TOGGLE_PRINT_MODAL,
    data,
  }),
  togglePrintTableModal: (data) => ({
    type: appUi.TOGGLE_PRINT_TABLE_MODAL,
    data,
  }),
  toggleAddStudentReportsModal: (data) => ({
    type: appUi.TOGGLE_ADD_STUDENT_REPORTS_MODAL,
    data,
  }),
  toggleIncomeStatementDetails: (data) => ({
    type: appUi.TOGGLE_INCOME_STATEMENT,
    data,
  }),
  toggleUpdateStudentReportsModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STUDENT_REPORTS_MODAL,
    data,
  }),
  toggleStudentReportsDetailsModal: (data) => ({
    type: appUi.TOGGLE_STUDENT_REPORTS_DETAILS_MODAL,
    data,
  }),
  setPrevSubApp: (data) => ({
    type: appUi.SET_PREV_SUB_APP,
    data,
  }),
  toggleAddAlevelGradingModal: (data) => ({
    type: appUi.TOGGLE_ADD_ALEVEL_GRADING_MODAL,
    data,
  }),
  toggleUpdateAlevelGradingModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ALEVEL_GRADING_MODAL,
    data,
  }),
  toggleAddMarksUploadModal: (data) => ({
    type: appUi.TOGGLE_ADD_MARKS_UPLOAD_MODAL,
    data,
  }),
  toggleUpdateMarksUploadModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_MARKS_UPLOAD_MODAL,
    data,
  }),
  toggleMarksUploadDetailsModal: (data) => ({
    type: appUi.TOGGLE_MARKS_UPLOAD_DETAILS_MODAL,
    data,
  }),
  toggleLedgerAccountDetailsModal: (data) => ({
    type: appUi.TOGGLE_LEDGER_ACCOUNT_DETAILS_MODAL,
    data,
  }),
  toggleMarksUploadTable: (data) => ({
    type: appUi.TOGGLE_MARKS_UPLOAD_TABLE,
    data,
  }),
  toggleAddStudentMarks: (data) => ({
    type: appUi.TOGGLE_ADD_STUDENT_MARKS_MODAL,
    data,
  }),
  toggleReportsModal: (data) => ({
    data,
    type: appUi.TOGGLE_REPORTS_MODAL,
  }),
  toggleUpdateStudentMarks: (data) => ({
    type: appUi.TOGGLE_UPDATE_STUDENT_MARKS_MODAL,
    data,
  }),
  toggleAddLedgerModal: (data) => ({
    type: appUi.TOGGLE_ADD_LEDGER_MODAL,
    data,
  }),
  toggleUpdateLedgerModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_LEDGER_MODAL,
    data,
  }),
  toggleLedgerDetailsModal: (data) => ({
    type: appUi.TOGGLE_LEDGER_DETAILS_MODAL,
    data,
  }),
  toggleUpdateBursaryCostModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BURSARY_COST_MODAL,
    data,
  }),
  toggleBursaryCostDetailsModal: (data) => ({
    type: appUi.TOGGLE_BURSARY_COST_DETAILS_MODAL,
    data,
  }),
  toggleAddBursariesModal: (data) => ({
    type: appUi.TOGGLE_ADD_BURSARIES_MODAL,
    data,
  }),
  toggleUpdateBursariesModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BURSARIES_MODAL,
    data,
  }),
  toggleBursariesDetailsModal: (data) => ({
    type: appUi.TOGGLE_BURSARIES_DETAILS_MODAL,
    data,
  }),
  toggleAddExaminationModal: (data) => ({
    type: appUi.TOGGLE_ADD_EXAMINATIONS_MODAL,
    data,
  }),
  toggleUpdateExaminationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_EXAMINATIONS_MODAL,
    data,
  }),
  toggleExaminationsDetailsModal: (data) => ({
    type: appUi.TOGGLE_EXAMINATIONS_DETAILS_MODAL,
    data,
  }),
  toggleAddStreamModal: (data) => ({
    type: appUi.TOGGLE_ADD_STREAMS_MODAL,
    data,
  }),
  toggleUpdateStreamsModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STREAM_MODAL,
    data,
  }),
  toggleStreamDetailsModal: (data) => ({
    type: appUi.TOGGLE_STREAM_DETAILS_MODAL,
    data,
  }),
  toggleUpdateTeacherAssignmentModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_TEACHER_ASSIGNMENT_MODAL,
    data,
  }),
  toggleAddTeacherAssignmentModal: (data) => ({
    type: appUi.TOGGLE_ADD_TEACHER_ASSIGNMENT_MODAL,
    data,
  }),
  toggleTeacherAssignmentDetailsModal: (data) => ({
    type: appUi.TOGGLE_TEACHER_ASSIGNMENT_DETAILS_MODAL,
    data,
  }),
  toggleAddSubjectClassModal: (data) => ({
    type: appUi.TOGGLE_ADD_SUBJECT_CLASS_MODAL,
    data,
  }),
  toggleUpdateSubjectClassModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SUBJECT_CLASS_MODAL,
    data,
  }),
  toggleSubjectClassDetailsModal: (data) => ({
    type: appUi.TOGGLE_SUBJECT_CLASS_DETAILS_MODAL,
    data,
  }),
  toggleAddAdvanceAccountabilityFormsModal: (data) => ({
    type: appUi.TOGGLE_ADD_ADVANCE_ACCOUNTABILITY_FORMS_MODAL,
    data,
  }),
  toggleUpdateAdvanceAccountabilityFormsModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ADVANCE_ACCOUNTABILITY_FORMS_MODAL,
    data,
  }),
  toggleAdvanceAccountabilityFormDetailsModal: (data) => ({
    type: appUi.TOGGLE_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_MODAL,
    data,
  }),
  toggleAddActualExpenditureModal: (data) => ({
    type: appUi.TOGGLE_ADD_ACTUAL_EXPENDITURE_MODAL,
    data,
  }),
  toggleUpdateActualExpenditureModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ACTUAL_EXPENDITURE_MODAL,
    data,
  }),
  toggleActualExpenditureDetailsModal: (data) => ({
    type: appUi.TOGGLE_ACTUAL_EXPENDITURE_DETAILS_MODAL,
    data,
  }),
  addNewApp: (data) => ({
    type: appUi.ADD_NEW_APP,
    data,
  }),
  removeApp: (data) => ({
    type: appUi.REMOVE_APP,
    data,
  }),
  setActiveApp: (data) => ({
    type: appUi.SET_ACTIVE_APP,
    data,
  }),
  setActiveSubApp: (data) => ({
    type: appUi.SET_ACTIVE_SUB_APP,
    data,
  }),
  toggleAppDrawer: (data) => ({
    type: appUi.TOGGLE_APP_DRAWER,
    data,
  }),
  toggleSubscriptionsPaymentModel: (data) => ({
    type: appUi.TOGGLE_SUBSCRIPTION_PAYMENTS_MODEL,
    data,
  }),
  toggleAddRoleModal: (data) => ({
    type: appUi.TOGGLE_ADD_ROLE_MODAL,
    data,
  }),
  toggleUpdateRolesModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ROLES_MODAL,
    data,
  }),
  toggleRolesDetailsModal: (data) => ({
    type: appUi.TOGGLE_ROLES_DETAILS_MODELS,
    data,
  }),
  toggleAddPermissionsModal: (data) => ({
    type: appUi.TOGGLE_ADD_PERMISSIONS_MODAL,
    data,
  }),
  toggleOrganisationLogoModal: (data) => ({
    type: appUi.TOGGLE_ORGANISATION_LOGO_MODAL,
    data,
  }),
  toggleAddManualsAndPoliciesModal: (data) => ({
    type: appUi.TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL,
    data,
  }),
  toggleUpdateManualsAndPoliciesModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_MANUALS_AND_POLICIES_MODAL,
    data,
  }),
  toggleManualsAndPoliciesDetailsModal: (data) => ({
    type: appUi.TOGGLE_MANUALS_AND_POLICIES_DETAILS_MODAL,
    data,
  }),
  toggleRejectionsModal: (data) => ({
    type: appUi.TOGGLE_REJECTIONS_MODAL,
    data,
  }),
  toggleForgotPasswordModal: (data) => ({
    type: appUi.TOGGLE_FORGOTPASSWORD_MODAL,
    data,
  }),
  toggleAddOrganisationUserModal: (data) => ({
    type: appUi.TOGGLE_ADD_ORGANISATION_USER_MODAL,
    data,
  }),
  toggleUpdateOrganisationUsersModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ORGANISATION_USERS_MODAL,
    data,
  }),
  toggleOrganisationUserDetailsModal: (data) => ({
    type: appUi.TOGGLE_ORGANISATION_USER_DETAILS_MODAL,
    data,
  }),
  toggleAddAccountTypesModal: (data) => ({
    type: appUi.TOGGLE_ADD_ACCOUNT_TYPES_MODAL,
    data,
  }),
  toggleUpdateAccountTypesModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ACCOUNT_TYPES_MODAL,
    data,
  }),
  toggleAccountTypeDetailsModal: (data) => ({
    type: appUi.TOGGLE_ACCOUNT_TYPE_DETAILS_MODAL,
    data,
  }),
  toggleAddAccountModal: (data) => ({
    type: appUi.TOGGLE_ADD_ACCOUNT_MODAL,
    data,
  }),
  toggleUpdateAccountModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ACCOUNT_MODAL,
    data,
  }),
  toggleAccountDetailsModal: (data) => ({
    type: appUi.TOGGLE_ACCOUNT_DETAILS_MODAL,
    data,
  }),
  toggleUploadUserProfileAvatarModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_USER_PROFILE_AVATAR_MODAL,
    data,
  }),
  toggleUploadUserSignatureModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_USER_SIGNATURE_MODAL,
    data,
  }),
  toggleChangePasswordModal: (data) => ({
    type: appUi.TOGGLE_CHANGE_PASSWORD_MODAL,
    data,
  }),
  toggleAddFinanceStreamModal: (data) => ({
    type: appUi.TOGGLE_ADD_FINANCE_STREAM_MODAL,
    data,
  }),
  toggleUpdateFinanceStreamModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_FINANCE_STREAM_MODAL,
    data,
  }),
  toggleFinanceStreamDetailsModal: (data) => ({
    type: appUi.TOGGLE_FINANCE_STREAM_DETAILS_MODAL,
    data,
  }),
  toggleAddStudentModal: (data) => ({
    type: appUi.TOGGLE_ADD_STUDENT_MODAL,
    data,
  }),
  toggleGetStudentDetailsModal: (data) => ({
    type: appUi.TOGGLE_GET_STUDENT_DETAILS_MODAL,
    data,
  }),
  toggleUpdateStudentDetailsModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STUDENT_DETAILS_MODAL,
    data,
  }),
  toggleAddBanksModal: (data) => ({
    type: appUi.TOGGLE_ADD_BANKS_MODAL,
    data,
  }),
  toggleUpdateBankModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BANK_MODAL,
    data,
  }),
  toggleGetBankDetails: (data) => ({
    type: appUi.TOGGLE_BANK_DETAILS_MODAL,
    data,
  }),
  toggleAddPartnerModal: (data) => ({
    type: appUi.TOGGLE_ADD_PARTNER_MODAL,
    data,
  }),
  toggleUpdatePartnerModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_PARTNER_MODAL,
    data,
  }),
  togglePartnerDetailsModal: (data) => ({
    type: appUi.TOGGLE_PARTNER_DETAILS_MODAL,
    data,
  }),
  toggleAddBudgetsModal: (data) => ({
    type: appUi.TOGGLE_ADD_BUDGETS_MODAL,
    data,
  }),
  toggleUpdateBudgetModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BUDGETS_MODAL,
    data,
  }),
  toggleBudgetDetailsPage: (data) => ({
    type: appUi.TOGGLE_BUDGET_DETAILS_PAGE,
    data,
  }),
  toggleAddBudgetLineModal: (data) => ({
    type: appUi.TOGGLE_ADD_BUDGET_LINE_MODAL,
    data,
  }),
  toggleUpdateBudgetLineModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BUDGET_LINE_MODAL,
    data,
  }),
  toggleBudgetLineDetailsModal: (data) => ({
    type: appUi.TOGGLE_BUDGET_LINE_DETAILS_MODAL,
    data,
  }),
  toggleUpdateBudgetStreamModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BUDGET_STREAM_MODAL,
    data,
  }),
  toggleBudgetStreamDetailsModal: (data) => ({
    type: appUi.TOGGLE_BUDGET_STREAM_DETAILS_MODAL,
    data,
  }),
  toggleAddAssetModal: (data) => ({
    type: appUi.TOGGLE_ADD_ASSET_MODAL,
    data,
  }),
  toggleUpdateAssetModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ASSET_MODAL,
    data,
  }),
  toggleAssetDetailsModal: (data) => ({
    type: appUi.TOGGLE_ASSET_DETAILS_MODAL,
    data,
  }),
  toggleAddAssetTypeModal: (data) => ({
    type: appUi.TOGGLE_ADD_ASSET_TYPE_MODAL,
    data,
  }),
  toggleUpdateAssetTypeModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ASSET_TYPE_MODAL,
    data,
  }),
  toggleAssetTypeDetailsModal: (data) => ({
    type: appUi.TOGGLE_ASSET_TYPE_DETAILS_MODAL,
    data,
  }),
  toggleIncomeReceiptDetailsModal: (data) => ({
    type: appUi.TOGGLE_INCOME_RECEIPT_DETAILS_MODAL,
    data,
  }),
  toggleAddIncomeReceiptModal: (data) => ({
    type: appUi.TOGGLE_ADD_INCOME_RECEIPT_MODAL,
    data,
  }),
  toggleUpdateIncomeReceiptModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_INCOME_RECEIPT_MODAL,
    data,
  }),
  toggleUpdateIncomeReceiptStreamModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_INCOME_RECEIPT_STREAM_MODAL,
    data,
  }),
  toggleAddIncomeReceiptStreamModal: (data) => ({
    type: appUi.TOGGLE_ADD_INCOME_RECEIPT_STREAM_MODAL,
    data,
  }),
  toggleIncomeReceiptStreamDetailsModal: (data) => ({
    type: appUi.TOGGLE_INCOME_RECEIPT_STREAM_DETAILS_MODAL,
    data,
  }),
  toggleAddIncomeReceiptDocumentModal: (data) => ({
    type: appUi.TOGGLE_ADD_INCOME_RECEIPT_DOCUMENT_MODAL,
    data,
  }),
  toggleAddStoreItemModal: (data) => ({
    type: appUi.TOGGLE_ADD_STORE_ITEM_MODAL,
    data,
  }),
  toggleUpdateStoreItemModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STORE_ITEM_MODAL,
    data,
  }),
  toggleStoreItemDetailsModal: (data) => ({
    type: appUi.TOGGLE_STORE_ITEM_DETAILS_MODAL,
    data,
  }),
  toggleAddStoreIssueModal: (data) => ({
    type: appUi.TOGGLE_ADD_STORE_ISSUE_MODAL,
    data,
  }),
  toggleUpdateStoreIssueModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STORE_ISSUE_MODAL,
    data,
  }),
  toggleStoreIssueDetailsModal: (data) => ({
    type: appUi.TOGGLE_STORE_ISSUE_DETAILS_MODAL,
    data,
  }),
  toggleRequisitionMemoDetailsModal: (data) => ({
    type: appUi.TOGGLE_REQUISITION_MEMO_DETAILS_MODAL,
    data,
  }),
  toggleAddRequisitionMemoModal: (data) => ({
    type: appUi.TOGGLE_ADD_REQUISITION_MEMO_MODAL,
    data,
  }),
  toggleUpdateRequisitionMemoModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_REQUISITION_MEMO_MODAL,
    data,
  }),
  toggleRequisitionMemoCostDetailsModal: (data) => ({
    type: appUi.TOGGLE_REQUISITION_MEMO_COST_DETAILS_MODAL,
    data,
  }),
  toggleAddRequisitionMemoCostModal: (data) => ({
    type: appUi.TOGGLE_ADD_REQUISITION_MEMO_COST_MODAL,
    data,
  }),
  toggleUpdateRequisitionMemoCostModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_REQUISITION_MEMO_COST_MODAL,
    data,
  }),
  toggleAddPaymentVoucherModal: (data) => ({
    type: appUi.TOGGLE_ADD_PAYMENT_VOUCHER_MODAL,
    data,
  }),
  toggleUpdatePaymentVoucherModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_PAYMENT_VOUCHER_MODAL,
    data,
  }),
  togglePaymentVoucherDetailsModal: (data) => ({
    type: appUi.TOGGLE_PAYMENT_VOUCHER_DETAILS_MODAL,
    data,
  }),
  toggleAddPaymentVoucherCostModal: (data) => ({
    type: appUi.TOGGLE_ADD_PAYMENT_VOUCHER_COST_MODAL,
    data,
  }),
  toggleAddProjectModal: (data) => ({
    type: appUi.TOGGLE_ADD_PROJECT_MODAL,
    data,
  }),
  toggleUpdateProjectModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_PROJECT_MODAL,
    data,
  }),
  toggleProjectDetailsModal: (data) => ({
    type: appUi.TOGGLE_PROJECT_DETAILS_MODAL,
    data,
  }),
  toggleAddRequestForQuotationModal: (data) => ({
    type: appUi.TOGGLE_ADD_REQUEST_FOR_QUOTATION_MODAL,
    data,
  }),
  toggleUpdateRequestForQuotationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_REQUEST_FOR_QUOTATION_MODAL,
    data,
  }),
  toggleRequestForQuotationDetailsModal: (data) => ({
    type: appUi.TOGGLE_REQUEST_FOR_QUOTATION_DETAILS_MODAL,
    data,
  }),

  toggleAddClassModal: (data) => ({
    type: appUi.TOGGLE_ADD_CLASS_MODAL,
    data,
  }),
  toggleUpdateClassModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_CLASS_MODAL,
    data,
  }),
  toggleClassDetailsModal: (data) => ({
    type: appUi.TOGGLE_CLASS_DETAILS_MODAL,
    data,
  }),
  toggleAddBidEvaluationModal: (data) => ({
    type: appUi.TOGGLE_ADD_BID_EVALUATION_MODAL,
    data,
  }),
  toggleUpdateBidEvaluationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BID_EVALUATION_MODAL,
    data,
  }),
  toggleBidEvaluationDetailsModal: (data) => ({
    type: appUi.TOGGLE_BID_EVALUATION_DETAILS_MODAL,
    data,
  }),
  toggleAddEligibilityEvaluationModal: (data) => ({
    type: appUi.TOGGLE_ADD_ELIGIBILITY_EVALUATION_MODAL,
    data,
  }),
  toggleUpdateEligibilityEvaluationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ELIGIBILITY_EVALUATION_MODAL,
    data,
  }),
  toggleEligibilityEvaluationDetailsModal: (data) => ({
    type: appUi.TOGGLE_ELIGIBILITY_EVALUATION_DETAILS_MODAL,
    data,
  }),
  toggleAddTechnicalEvaluationModal: (data) => ({
    type: appUi.TOGGLE_ADD_TECHNICAL_EVALUATION_MODAL,
    data,
  }),
  toggleUpdateTechnicalEvaluationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_TECHNICAL_EVALUATION_MODAL,
    data,
  }),
  toggleTechnicalEvaluationDetailsModal: (data) => ({
    type: appUi.TOGGLE_TECHNICAL_EVALUATION_DETAILS_MODAL,
    data,
  }),
  toggleAddFinancialEvaluationModal: (data) => ({
    type: appUi.TOGGLE_ADD_FINANCIAL_EVALUATION_MODAL,
    data,
  }),
  toggleUpdateFinancialEvaluationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_FINANCIAL_EVALUATION_MODAL,
    data,
  }),
  toggleFinancialEvaluationDetailsModal: (data) => ({
    type: appUi.TOGGLE_FINANCIAL_EVALUATION_DETAILS_MODAL,
    data,
  }),
  toggleAddNoticeOfBestBidderModal: (data) => ({
    type: appUi.TOGGLE_ADD_NOTICE_OF_BEST_BIDDER_MODAL,
    data,
  }),
  toggleUpdateNoticeOfBestBidderModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_NOTICE_OF_BEST_BIDDER_MODAL,
    data,
  }),
  toggleNoticeOfBestBidderDetailsModal: (data) => ({
    type: appUi.TOGGLE_NOTICE_OF_BEST_BIDDER_DETAILS_MODAL,
    data,
  }),
  toggleAddLocalPurchaseOrderModal: (data) => ({
    type: appUi.TOGGLE_ADD_LOCAL_PURCHASE_ORDER_MODAL,
    data,
  }),
  toggleUpdateLocalPurchaseOrderModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_MODAL,
    data,
  }),
  toggleLocalPurchaseOrderDetailsModal: (data) => ({
    type: appUi.TOGGLE_LOCAL_PURCHASE_ORDER_DETAILS_MODAL,
    data,
  }),
  toggleAddLocalPurchaseOrderItemModal: (data) => ({
    type: appUi.TOGGLE_ADD_LOCAL_PURCHASE_ORDER_ITEM_MODAL,
    data,
  }),
  toggleUpdateLocalPurchaseOrderItemModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_MODAL,
    data,
  }),
  toggleLocalPurchaseOrderItemDetailsModal: (data) => ({
    type: appUi.TOGGLE_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_MODAL,
    data,
  }),
  toggleAddAcademicYearModal: (data) => ({
    type: appUi.TOGGLE_ADD_ACADEMIC_YEAR_MODAL,
    data,
  }),
  toggleUpdateAcademicYearsModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ACADEMIC_YEAR_MODAL,
    data,
  }),
  toggleAcademicYearDetailsModal: (data) => ({
    type: appUi.TOGGLE_ACADEMIC_YEARS_DETAILS_MODAL,
    data,
  }),
  toggleTermDetailsModal: (data) => ({
    type: appUi.TOGGLE_TERM_DETAILS_MODAL,
    data,
  }),
  toggleAddPaymentStructure: (data) => ({
    type: appUi.TOGGLE_ADD_PAYMENT_STRUCTURES_MODAL,
    data,
  }),
  toggleUpdatePaymentStructure: (data) => ({
    type: appUi.TOGGLE_UPDATE_PAYMENT_STRUCTURE_MODAL,
    data,
  }),
  togglePaymentStructureDetailsModal: (data) => ({
    type: appUi.TOGGLE_PAYMENT_STRUCTURE_DETAILS_MODAL,
    data,
  }),
  toggleUpdaPaymentStructureCostModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_PAYMENT_STRUCTURE_COST_MODAL,
    data,
  }),
  togglePaymentStructureCostDetailsModal: (data) => ({
    type: appUi.TOGGLE_PAYMENT_STRUCTURE_COST_DETAILS_MODAL,
    data,
  }),
  toggleAddEnrollmentModal: (data) => ({
    type: appUi.TOGGLE_ADD_ENROLLMENT_MODAL,
    data,
  }),
  toggleEnrollStudentsModal: (data) => ({
    type: appUi.TOGGLE_ENROLL_STUDENTS_MODAL,
    data,
  }),
  toggleEnrollmentDetailsModal: (data) => ({
    type: appUi.TOGGLE_ENROLLMENT_DETAILS_MODAL,
    data,
  }),
  toggleAddOptionalSubjectModal: (data) => ({
    type: appUi.TOGGLE_ADD_OPTIONAL_SUBJECT_MODAL,
    data,
  }),
  toggleAddOptionalSubjectStudentsModal: (data) => ({
    type: appUi.TOGGLE_ADD_OPTIONAL_SUBJECT_STUDENTS_MODAL,
    data,
  }),
  toggleOptionalSubjectDetailsModal: (data) => ({
    type: appUi.TOGGLE_OPTIONAL_SUBJECT_DETAILS_MODAL,
    data,
  }),
  toggleAddSubjectModal: (data) => ({
    type: appUi.TOGGLE_ADD_SUBJECT_MODAL,
    data,
  }),
  toggleUpdateSubjectModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SUBJECT_MODAL,
    data,
  }),
  toggleSubjectDetailsModal: (data) => ({
    type: appUi.TOGGLE_SUBJECT_DETAILS_MODAL,
    data,
  }),
  toggleAddRecordOfBiddersModal: (data) => ({
    type: appUi.TOGGLE_ADD_RECORD_OF_BIDDERS_MODAL,
    data,
  }),
  toggleUpdateRecordOfBiddersModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_RECORD_OF_BIDDERS_MODAL,
    data,
  }),
  toggleRecordOfBiddersDetailsModal: (data) => ({
    type: appUi.TOGGLE_RECORD_OF_BIDDERS_DETAILS_MODAL,
    data,
  }),
  toggleAddBidderModal: (data) => ({
    type: appUi.TOGGLE_ADD_BIDDER_MODAL,
    data,
  }),
  toggleUpdateBidderModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_BIDDER_MODAL,
    data,
  }),
  toggleBidderDetailsModal: (data) => ({
    type: appUi.TOGGLE_BIDDER_DETAILS_MODAL,
    data,
  }),
  toggleAddGradingModal: (data) => ({
    type: appUi.TOGGLE_ADD_GRADING_MODAL,
    data,
  }),
  toggleUpdateGradingModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_GRADING_MODAL,
    data,
  }),
  toggleGradingDetailsModal: (data) => ({
    type: appUi.TOGGLE_GRADING_DETAILS_MODAL,
    data,
  }),
  toggleUploadStudentDocumentsModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_STUDENT_DOCUMENTS_MODAL,
    data,
  }),
  togglePrintSubjectsTableModal: (data) => ({
    type: appUi.TOGGLE_PRINT_SUBJECTS_TABLE_MODAL,
    data,
  }),
  togglePrintStudentsTableModal: (data) => ({
    type: appUi.TOGGLE_PRINT_STUDENTS_TABLE_MODAL,
    data,
  }),
  togglePrintOptionalSubjectsTableModal: (data) => ({
    type: appUi.TOGGLE_PRINT_OPTIONAL_SUBJECTS_TABLE_MODAL,
    data,
  }),
  togglePrintEnrollmentTableModal: (data) => ({
    type: appUi.TOGGLE_PRINT_ENROLLMENT_TABLE_MODAL,
    data,
  }),
  toggleAddStaffDependantsModal: (data) => ({
    type: appUi.TOGGLE_ADD_STAFF_DEPENDANTS_MODAL,
    data,
  }),
  toggleUpdateStaffDependantsModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STAFF_DEPENDANTS_MODAL,
    data,
  }),
  toggleUploadStaffDocumentsModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_STAFF_DOCUMENTS_MODAL,
    data,
  }),
  toggleUpdateStaffDocumentsModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STAFF_DOCUMENTS_MODAL,
    data,
  }),
  toggleDownloadOptionalSubjectStudentsModal: (data) => ({
    type: appUi.TOGGLE_DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL,
    data,
  }),
  toggleUploadOptionalSubjectStudentsModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL,
    data,
  }),
  toggleUpdateEnrollmentModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_ENROLLMENT_MODAL,
    data,
  }),
  toggleAddStaffQualificationModal: (data) => ({
    type: appUi.TOGGLE_ADD_STAFF_QUALIFICATION_MODAL,
    data,
  }),
  toggleUpdateStaffQualificationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STAFF_QUALIFICATION_MODAL,
    data,
  }),
  toggleStaffQualificationDetailsModal: (data) => ({
    type: appUi.TOGGLE_STAFF_QUALIFICATION_DETAILS_MODAL,
    data,
  }),
  toggleBankStatementModal: (data) => ({
    type: appUi.TOGGLE_BANK_STATEMENT_MODAL,
    data,
  }),
  toggleSearchStudentsModal: (data) => ({
    type: appUi.TOGGLE_SEARCH_STUDENTS_MODAL,
    data,
  }),
  toggleSortIncomeReceiptsModal: (data) => ({
    type: appUi.TOGGLE_SORT_INCOME_RECEIPTS_MODAL,
    data,
  }),
  toggleUpdateUserEmailModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_USER_EMAIL_MODAL,
    data,
  }),
  toggleUploadAdvanceAccountabilityDocumentsModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_MODAL,
    data,
  }),
  toggleSortRequisitionMemosModal: (data) => ({
    type: appUi.TOGGLE_SORT_REQUISITION_MEMOS_MODAL,
    data,
  }),
  toggleSortAdvanceAccountabilityModal: (data) => ({
    type: appUi.TOGGLE_SORT_ADVANCE_ACCOUNTABILITY_MODAL,
    data,
  }),
  toggleSortModal: (data) => ({
    type: appUi.TOGGLE_SORT_MODAL,
    data,
  }),
  toggleSortFromTableModal: (data) => ({
    type: appUi.TOGGLE_SORT_FROM_TABLE_MODAL,
    data,
  }),
  toggleAddDivisionsModal: (data) => ({
    type: appUi.TOGGLE_ADD_DIVISIONS_MODAL,
    data,
  }),
  toggleUpdateDivisionModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_DIVISION_MODAL,
    data,
  }),
  toggleFeesDefaulterDetailsModal: (data) => ({
    type: appUi.TOGGLE_FEES_DEFAULTER_DETAILS_MODAL,
    data,
  }),
  toggleReportCommentsModal: (data) => ({
    type: appUi.TOGGLE_REPORT_COMMENTS_MODAL,
    data,
  }),
  toggleMandatoryBillingModal: (data) => ({
    type: appUi.TOGGLE_MANDATORY_BILLING_MODAL,
    data,
  }),
  toggleBillLearnersModal: (data) => ({
    type: appUi.TOGGLE_BILL_LEARNERS_MODAL,
    data,
  }),
  toggleCreateReportExceptionModal: (data) => ({
    type: appUi.TOGGLE_CREATE_REPORT_EXCEPTION_MODAL,
    data,
  }),
  toggleUpdateReportExceptionModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_REPORT_EXCEPTION_MODAL,
    data,
  }),
  toggleAddSingleReportExceptionModal: (data) => ({
    type: appUi.TOGGLE_ADD_SINGLE_REPORT_EXCEPTION_MODAL,
    data,
  }),
  toggleSupplementariesModal: (data) => ({
    type: appUi.TOGGLE_SUPPLEMENTARIES_MODAL,
    data,
  }),
  toggleLearningAreasModal: (data) => ({
    type: appUi.TOGGLE_LEARNING_AREAS_MODAL,
    data,
  }),
  toggleAddSupplierPaymentScheduleModal: (data) => ({
    type: appUi.TOGGLE_ADD_SUPPLIER_PAYMENT_SCHEDULE_MODAL,
    data,
  }),
  toggleUpdateSupplierPaymentScheduleModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_MODAL,
    data,
  }),
  toggleSupplierPaymentScheduleDetailsModal: (data) => ({
    type: appUi.TOGGLE_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_MODAL,
    data,
  }),
  toggleAddSupplierPaymentModal: (data) => ({
    type: appUi.TOGGLE_ADD_SUPPLIER_PAYMENT_MODAL,
    data,
  }),
  toggleUpdateSupplierPaymentModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SUPPLIER_PAYMENT_MODAL,
    data,
  }),
  toggleSupplierPaymentDetailsModal: (data) => ({
    type: appUi.TOGGLE_SUPPLIER_PAYMENT_DETAILS_MODAL,
    data,
  }),
  toggleAddLearnerAttendanceModal: (data) => ({
    type: appUi.TOGGLE_ADD_LEARNER_ATTENDANCE_MODAL,
    data,
  }),
  toggleUpdateLearnerAttendanceModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_LEARNER_ATTENDANCE_MODAL,
    data,
  }),
  toggleLearnerAttendanceDetailsModal: (data) => ({
    type: appUi.TOGGLE_LEARNER_ATTENDANCE_DETAILS_MODAL,
    data,
  }),
  toggleAddStaffAttendanceModal: (data) => ({
    type: appUi.TOGGLE_ADD_STAFF_ATTENDANCE_MODAL,
    data,
  }),
  toggleUpdateStaffAttendanceModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STAFF_ATTENDANCE_MODAL,
    data,
  }),
  toggleStaffAttendanceDetailsModal: (data) => ({
    type: appUi.TOGGLE_STAFF_ATTENDANCE_DETAILS_MODAL,
    data,
  }),
  toggleAddSupplierPaymentInvoiceModal: (data) => ({
    type: appUi.TOGGLE_ADD_SUPPLIER_PAYMENT_INVOICE_MODAL,
    data,
  }),
  toggleUpdateSupplierPaymentInvoiceModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SUPPLIER_PAYMENT_INVOICE_MODAL,
    data,
  }),
  toggleAddGeneralSupplierPaymentInvoiceModal: (data) => ({
    type: appUi.TOGGLE_ADD_GENERAL_SUPPLIER_PAYMENT_INVOICE_MODAL,
    data,
  }),
  toggleAddSchoolDevelopmentPlanModal: (data) => ({
    type: appUi.TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_MODAL,
    data,
  }),
  toggleUpdateSchoolDevelopmentPlanModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_MODAL,
    data,
  }),
  toggleSchoolDevelopmentPlanDetailsModal: (data) => ({
    type: appUi.TOGGLE_SCHOOL_DEVELOPMENT_PLAN_DETAILS_MODAL,
    data,
  }),
  toggleAddSchoolDevelopmentPlanExpenditureModal: (data) => ({
    type: appUi.TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL,
    data,
  }),
  toggleUpdateSchoolDevelopmentPlanExpenditureModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL,
    data,
  }),
  toggleSchoolDevelopmentPlanExpenditureDetailsModal: (data) => ({
    type: appUi.TOGGLE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DETAILS_MODAL,
    data,
  }),
  toggleAddStaffPayRollModal: (data) => ({
    type: appUi.TOGGLE_ADD_STAFF_PAYROLL_MODAL,
    data,
  }),
  toggleUpdateStaffPayRollModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_STAFF_PAYROLL_MODAL,
    data,
  }),
  toggleStaffPayRollDetailsModal: (data) => ({
    type: appUi.TOGGLE_STAFF_PAYROLL_DETAILS_MODAL,
    data,
  }),
  toggleAddClientInvoiceModal: (data) => ({
    type: appUi.TOGGLE_ADD_CLIENT_INVOICE_MODAL,
    data,
  }),
  toggleUpdateClientInvoiceModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_CLIENT_INVOICE_MODAL,
    data,
  }),
  toggleClientInvoiceDetailsModal: (data) => ({
    type: appUi.TOGGLE_CLIENT_INVOICE_DETAILS_MODAL,
    data,
  }),
  toggleIncomeStatementAccountDetailsModal: (data) => ({
    type: appUi.TOGGLE_INCOME_STATEMENT_ACCOUNT_DETAILS_MODAL,
    data,
  }),
  toggleAddSchoolGatePassModal: (data) => ({
    type: appUi.TOGGLE_ADD_SCHOOL_GATE_PASS_MODAL,
    data,
  }),
  toggleUpdateSchoolGatePassModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SCHOOL_GATE_PASS_MODAL,
    data,
  }),
  toggleSchoolGatePassDetailsModal: (data) => ({
    type: appUi.TOGGLE_SCHOOL_GATE_PASS_DETAILS_MODAL,
    data,
  }),

  toggleAddSchoolTimeTableModal: (data) => ({
    type: appUi.TOGGLE_ADD_SCHOOL_TIME_TABLE_MODAL,
    data,
  }),

  toggleUpdateSchoolTimeTableModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SCHOOL_TIME_TABLE_MODAL,
    data,
  }),

  toggleSchoolTimeTableDetailsModal: (data) => ({
    type: appUi.TOGGLE_SCHOOL_TIME_TABLE_DETAILS_MODAL,
    data,
  }),

  toggleAddSchoolTimeTableActivityModal: (data) => ({
    type: appUi.TOGGLE_ADD_SCHOOL_TIME_TABLE_ACTIVITY_MODAL,
    data,
  }),

  toggleUpdateSchoolTimeTableActivityModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SCHOOL_TIME_TABLE_ACTIVITY_MODAL,
    data,
  }),

  toggleAddLessonPlanModal: (data) => ({
    type: appUi.TOGGLE_ADD_LESSON_PLAN_MODAL,
    data,
  }),

  toggleUpdateLessonPlanModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_LESSON_PLAN_MODAL,
    data,
  }),

  toggleLessonPlanDetailsModal: (data) => ({
    type: appUi.TOGGLE_LESSON_PLAN_DETAILS_MODAL,
    data,
  }),

  toggleAddLessonPlanProcedureStageModal: (data) => ({
    type: appUi.TOGGLE_ADD_LESSON_PLAN_PROCEDURE_STAGE_MODAL,
    data,
  }),

  toggleUpdateLessonPlanProcedureStageModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_MODAL,
    data,
  }),

  toggleAddIndisciplineCaseModal: (data) => ({
    type: appUi.TOGGLE_ADD_INDISCIPLINE_CASE_MODAL,
    data,
  }),

  toggleUpdateIndisciplineCaseModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_INDISCIPLINE_CASE_MODAL,
    data,
  }),

  toggleIndisciplineCaseDetailsModal: (data) => ({
    type: appUi.TOGGLE_INDISCIPLINE_CASE_DETAILS_MODAL,
    data,
  }),

  toggleAddSubjectCombinationModal: (data) => ({
    type: appUi.TOGGLE_ADD_SUBJECT_COMBINATION_MODAL,
    data,
  }),

  toggleUpdateSubjectCombinationModal: (data) => ({
    type: appUi.TOGGLE_UPDATE_SUBJECT_COMBINATION_MODAL,
    data,
  }),

  toggleSubjectCombinationDetailsModal: (data) => ({
    type: appUi.TOGGLE_SUBJECT_COMBINATION_DETAILS_MODAL,
    data,
  }),

  toggleUploadSubjectCombinationDocumentModal: (data) => ({
    type: appUi.TOGGLE_UPLOAD_SUBJECT_COMBINATION_DOCUMENT_MODAL,
    data,
  }),
};

export default appUi;
